import { Button, File, Flex, Ionicons, Text, UserAvatar, View } from '@ui/atoms';
import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';
import { Alert, Panel, TextCollapse } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import SURVEY_TYPE from '@constants/SurveyType';
import useSurveyQuery from '@hooks/queries/useSurveyQuery';
import PropTypes from 'prop-types';
import useRelativeDate from '@hooks/useRelativeDate';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import useOpenFile from '@hooks/useOpenFile';
import useAnswerSurveyMutation from '@hooks/mutations/useAnswerSurveyMutation';
import CustomPropTypes from '@utils/CustomPropTypes';
import useOpenUserProfile from '@hooks/useOpenUserProfile';
import SurveySkeleton from '@ui/organisms/SurveySkeleton';
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';
import SurveyActions from './SurveyActions';
import RatingForm from './Rating/RatingForm';
import RatingResult from './Rating/RatingResult';
import RatingWait from './Rating/RatingWait';
import ChoicesForm from './Choices/ChoicesForm';
import ChoicesResult from './Choices/ChoicesResult';
import ChoicesWait from './Choices/ChoicesWait';

const TYPES = {
  [SURVEY_TYPE.rating]: {
    Form: RatingForm,
    Result: RatingResult,
    Wait: RatingWait,
  },
  [SURVEY_TYPE.choices]: {
    Form: ChoicesForm,
    Result: ChoicesResult,
    Wait: ChoicesWait,
  },
};

function Survey({ id, visible, borderless = false, sx = undefined, onNotFoundError = undefined }) {
  const { t } = useTranslate();
  const { navigate } = useNavigation();
  const formatRelative = useRelativeDate();
  const openFile = useOpenFile();
  const openUserProfile = useOpenUserProfile();

  const [editing, setEditing] = useState(false);

  const {
    data: survey,
    isError,
    isLoading,
    isFetched,
  } = useSurveyQuery(id, null, {
    refetchInterval: 30 * 1000,
    enabled: visible,
    onError: (err) => {
      if (err.response?.status === 404 && typeof onNotFoundError === 'function') {
        onNotFoundError(err);
      }
    },
  });

  const { mutate, isLoading: isAnswering } = useAnswerSurveyMutation(id, {
    onSuccess: () => setEditing(false),
  });

  const handleEdit = () => setEditing(true);

  const handleProfileView = () => openUserProfile(survey.user.id);

  const handleSeeAnswers = () => navigate(SCREENS.surveyAnswers, { id });

  if (isError) {
    return null;
  }

  if (isLoading || !isFetched) {
    return <SurveySkeleton sx={sx} borderless={borderless} />;
  }

  const expired = new Date(survey.expiresAt).getTime() < new Date().getTime();
  const answered = survey.currentUserAnswered;
  const realtime = survey.realtimeResults;
  const editable = survey.permissions.answer && !expired && answered && !editing;

  const answerRequired = !answered && !expired;
  const showForm = answerRequired || editing;
  const showResults = !showForm && (expired || (realtime && answered));
  const waitResults = answered && !showForm && !expired && !realtime;

  const { Form, Result, Wait } = TYPES[survey.type] ?? {};
  const isSupported = !!TYPES[survey.type];

  const Root = borderless ? View : Panel;

  return (
    <Root sx={sx}>
      <Flex alignItems="center" sx={{ mb: '$5' }}>
        <UserAvatar sx={{ mr: '$2' }} size="large" user={survey.user} onPress={handleProfileView} />
        <View style={{ flex: 1 }}>
          <Flex alignItems="center">
            <Text>{capitalizeFirstLetter(formatRelative(new Date(survey.createdAt)))}</Text>
            {survey.createdAt !== survey.updatedAt && (
              <Text sx={{ fontSize: 10, ml: '$1', color: '$grey.600' }}>
                ({t('common.updated', { conjugation: 'feminine' })})
              </Text>
            )}
          </Flex>
          <Text variant="subtitle" sx={{ color: '$primary.main' }}>
            {survey.user.fullname}
          </Text>
        </View>
        {isSupported && <SurveyActions survey={survey} />}
      </Flex>
      <View>
        <Text variant="subtitle">{survey.title}</Text>
        <View sx={{ mt: '$2' }}>
          <TextCollapse numberOfPreviewLines={6}>{survey.content}</TextCollapse>
        </View>
      </View>
      {survey?.file && (
        <View sx={{ mt: '$4' }}>
          <File label={survey.file.name} onPress={() => openFile(survey.file)} />
        </View>
      )}

      {isSupported ? (
        <>
          <View
            sx={{
              borderTopWidth: 1,
              borderStyle: 'solid',
              borderColor: '$primary.main',
              my: '$4',
            }}
          />

          {showForm && <Form survey={survey} onSubmit={mutate} loading={isAnswering} />}
          {waitResults && <Wait survey={survey} />}
          {showResults && <Result survey={survey} />}

          {editable && (
            <View sx={{ mt: '$6' }}>
              <Button
                color="primary"
                title={t('ui.Survey.modify')}
                startIcon={<Ionicons name="pencil" size={24} sx={{ color: '$common.white' }} />}
                onPress={handleEdit}
              />
            </View>
          )}

          {!expired && !answered && (
            <Text sx={{ color: '$primary.main', textAlign: 'center', fontSize: 12, mt: '$2' }}>
              <FormattedMessage id="ui.Survey.endAt" values={{ expiresAt: new Date(survey.expiresAt) }} />
            </Text>
          )}

          {survey.permissions.seeAnswers && (
            <View sx={{ mt: editable ? '$2' : '$6' }}>
              <Button color="primary" variant="outlined" title={t('ui.Survey.seeAnswers')} onPress={handleSeeAnswers} />
            </View>
          )}
        </>
      ) : (
        <Alert type="error" sx={{ mt: '$4' }} message={t('ui.Survey.notSupported')} />
      )}
    </Root>
  );
}

Survey.propTypes = {
  id: CustomPropTypes.uuid.isRequired,
  visible: PropTypes.bool.isRequired,
  sx: CustomPropTypes.style,
  borderless: PropTypes.bool,
  onNotFoundError: PropTypes.func,
};

export default Survey;
