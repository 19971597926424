// eslint-disable-next-line no-restricted-imports
import { Text as DefaultText } from 'dripsy';
import { forwardRef } from 'react';
import CustomPropTypes from '@utils/CustomPropTypes';

const Text = forwardRef(function Text({ sx = undefined, ...props }, ref) {
  return <DefaultText ref={ref} sx={{ color: '$text.primary', ...sx }} {...props} />;
});

Text.propTypes = {
  sx: CustomPropTypes.style,
};

export default Text;
