import { Chip, Flex, Text, UserAvatar, View } from '@ui/atoms';
import POST_TYPE from '@constants/PostType';
import { FormattedMessage } from 'react-intl';
import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';
import useTranslate from '@hooks/useTranslate';
import useRelativeDate from '@hooks/useRelativeDate';
import useOpenUserProfile from '@hooks/useOpenUserProfile';
import CustomPropTypes from '@utils/CustomPropTypes';
import PostActions from '@ui/organisms/Post/PostActions';
import { usePost } from '@ui/organisms/Post/postContext';

function PostHeader({ sx = undefined }) {
  const { t } = useTranslate();
  const formatRelative = useRelativeDate();
  const openUserProfile = useOpenUserProfile();

  const post = usePost();

  const handleProfileView = () => openUserProfile(post.user.id);

  return post.type === POST_TYPE.document ? (
    <Flex alignItems="center" justifyContent="space-between" sx={sx}>
      <Text variant="subtitle">
        <FormattedMessage
          id="ui.Post.PostHeader.documentTitle"
          values={{ fullname: post.user.fullname, date: new Date(post.createdAt) }}
        />
      </Text>
      <PostActions />
    </Flex>
  ) : (
    <View>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" wrap>
          <Chip
            label={t('constants.PostType', { value: post.type })}
            size="extraSmall"
            variant="primary"
            sx={{ mr: '$2', mb: '$2' }}
            active
          />
          {post.category && (
            <Chip label={post.category.name} size="extraSmall" variant="secondary" sx={{ mb: '$2' }} active />
          )}
        </Flex>
        <PostActions />
      </Flex>
      <Flex alignItems="center">
        <UserAvatar sx={{ mr: '$2' }} size="large" user={post.user} onPress={handleProfileView} />
        <View>
          <Flex alignItems="center">
            <Text>{capitalizeFirstLetter(formatRelative(new Date(post.createdAt)))}</Text>
            {post.createdAt !== post.updatedAt && (
              <Text sx={{ fontSize: 10, ml: '$1', color: '$grey.600' }}>
                ({t('common.updated', { conjugation: 'feminine' })})
              </Text>
            )}
          </Flex>
          <Text variant="subtitle" sx={{ color: '$primary.main' }}>
            {post.user.fullname}
          </Text>
        </View>
      </Flex>
    </View>
  );
}

PostHeader.propTypes = {
  sx: CustomPropTypes.style,
};

export default PostHeader;
