import { ActivityIndicator, Image, Ionicons, Pressable, ScrollView, View } from '@ui/atoms';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import useThemeColor from '@hooks/useThemeColor';

const MAX_PLACEHOLDER = 5;

function ImagesList({ onPress, max = null, loading = false, images = Object.freeze([]), onDelete = undefined }) {
  const primaryColor = useThemeColor('$primary.main');

  const count = Math.min(MAX_PLACEHOLDER - images.length, max - images.length);
  const emptyItems = [...Array(0 >= count && max > images.length ? 1 : count).keys()];

  const firstPlaceholder = loading ? (
    <ActivityIndicator color={primaryColor} />
  ) : (
    <Ionicons name="camera-outline" size={32} sx={{ color: '$text.primary' }} />
  );

  return (
    <ScrollView
      sx={{ m: '$-5' }}
      contentContainerSx={{ flexDirection: 'row', p: '$5' }}
      showsHorizontalScrollIndicator={false}
      horizontal
    >
      {images.map((image) => (
        <View
          key={image.id}
          sx={{
            position: 'relative',
            mr: '$2',
            height: 70,
            width: 70,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: '$grey.400',
            borderRadius: '$1',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            key={image.id}
            sx={{
              height: 70,
              width: 70,
              resizeMode: 'contain',
            }}
            source={{ uri: image.uri }}
          />
          {typeof onDelete === 'function' && (
            <Pressable
              onPress={() => onDelete(image)}
              sx={{
                position: 'absolute',
                top: -6,
                right: -6,
                bg: '$red.main',
                borderRadius: 20,
                height: 20,
                width: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Ionicons name="close" size={18} sx={{ color: '$common.white' }} />
            </Pressable>
          )}
        </View>
      ))}
      {emptyItems.map((id) => (
        <Pressable
          key={id}
          onPress={onPress}
          sx={{
            height: 70,
            width: 70,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: '$grey.400',
            mr: '$2',
            borderRadius: '$1',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {id === 0 && firstPlaceholder}
        </Pressable>
      ))}
    </ScrollView>
  );
}

ImagesList.propTypes = {
  onPress: PropTypes.func.isRequired,
  max: PropTypes.number,
  loading: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: CustomPropTypes.uuid,
      uri: PropTypes.string,
    })
  ),
  onDelete: PropTypes.func,
};

export default ImagesList;
