import { Flex, Image, Text, View } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';
import useEventQuery from '@hooks/queries/useEventQuery';
import SCREENS from '@constants/Screens';
import { FormattedMessage } from 'react-intl';
import parseISO from 'date-fns/parseISO';
import userPlaceholder from '@src/assets/user-placeholder.jpg';
import { TouchableOpacity } from 'react-native';
import Avatar from '@ui/atoms/Avatar';
import { useNavigation } from '@react-navigation/native';
import ReplyEvent from '@ui/organisms/ReplyEvent';
import EventCardSkeleton from '@ui/organisms/EventCardSkeleton';
import useMeQuery from '@hooks/queries/useMeQuery';

function EventCard({ id = undefined, disabled = false, visible = false, sx = undefined }) {
  const { navigate } = useNavigation();
  const { data: me } = useMeQuery();

  const {
    data: event,
    isLoading,
    isError,
    isFetched,
  } = useEventQuery(id, {
    enabled: visible,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const Container = disabled ? View : TouchableOpacity;

  if (isError) {
    return null;
  }

  if (isLoading || !isFetched) {
    return <EventCardSkeleton sx={sx} />;
  }

  const handleOpen = () => navigate(SCREENS.event, { id: event.id });

  return (
    <Container onPress={disabled ? undefined : handleOpen}>
      <View
        sx={{
          borderWidth: 1,
          borderColor: '$grey.300',
          borderRadius: '$1',
          overflow: 'hidden',
          backgroundColor: '$common.white',
          ...sx,
        }}
      >
        <Image
          source={{ uri: event.thumbnail.small }}
          sx={{
            width: '100%',
            height: 250,
            resizeMode: 'cover',
            bg: '$grey.300',
          }}
        />
        <View sx={{ p: '$4' }}>
          <Flex>
            {event.canceledAt ? (
              <Text sx={{ color: '$red.main', fontSize: 12 }}>
                <FormattedMessage id="ui.EventCard.canceled" />
              </Text>
            ) : (
              <Text sx={{ color: '$primary.main', fontSize: 12 }}>
                <FormattedMessage id="ui.EventCard.startAt" values={{ date: parseISO(event.startAt) }} />
              </Text>
            )}
          </Flex>
          <Text variant="subtitle">{event.title}</Text>
          <Text sx={{ color: '$grey.700', fontSize: 12 }}>{event.location}</Text>
          <Flex
            direction={['column', null, 'row']}
            justifyContent="space-between"
            alignItems={['flex-start', null, 'center']}
          >
            <View sx={{ mt: '$2', flexDirection: 'row', alignItems: 'center' }}>
              <Avatar size="small" source={event?.user?.avatar?.small || userPlaceholder} />
              <Text sx={{ ml: '$2', fontSize: 11 }}>
                <FormattedMessage id="ui.EventCard.organizer" values={{ fullname: event.user.fullname }} />
              </Text>
            </View>
            <View sx={{ width: ['100%', null, 'auto'] }}>
              {me.id !== event.user.id && (
                <ReplyEvent
                  id={event.id}
                  disabled={!event.permissions.reply}
                  currentReply={event.currentUserReply}
                  sx={{ mt: ['$4', null, 0] }}
                />
              )}
            </View>
          </Flex>
        </View>
      </View>
    </Container>
  );
}

EventCard.propTypes = {
  id: CustomPropTypes.uuid,
  disabled: PropTypes.bool,
  sx: CustomPropTypes.style,
  visible: PropTypes.bool,
};

export default EventCard;
