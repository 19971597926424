import { Text, View } from '@ui/atoms';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

const COLORS = {
  error: '$red.main',
  warning: '$secondary.main',
};

function Alert({ message, type, sx = undefined }) {
  return (
    <View
      sx={{
        borderWidth: 1,
        borderColor: COLORS[type],
        borderRadius: '$1',
        height: 'auto',
        p: '$4',
        ...sx,
      }}
    >
      <Text sx={{ color: COLORS[type] }}>{message}</Text>
    </View>
  );
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'warning']).isRequired,
  sx: CustomPropTypes.style,
};

export default Alert;
