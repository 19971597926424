/* eslint-disable react/jsx-props-no-spreading */
import Image from '@ui/atoms/Image';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

export const SIZES = {
  small: 16,
  medium: 32,
  large: 50,
  extraLarge: 124,
};

function Avatar({ source, size = 'medium', sx = undefined, ...props }) {
  return (
    <Image
      source={typeof source === 'string' ? { uri: source } : source}
      sx={{
        height: SIZES[size],
        width: SIZES[size],
        borderRadius: SIZES[size] / 2,
        backgroundColor: '$common.white',
        ...sx,
      }}
      {...props}
    />
  );
}

Avatar.propTypes = {
  source: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
  sx: CustomPropTypes.style,
};

export default Avatar;
