import { Chip, ScrollView } from '@ui/atoms';
import { getVisiblePostTypes } from '@constants/PostType';
import useTranslate from '@hooks/useTranslate';
import FEED_ITEM_TYPE from '@constants/FeedItemType';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import useCurrentResidenceQuery from '@hooks/queries/useCurrentResidenceQuery';

const ALL = 'all';

function FeedMobileFilters({
  selectedTypes,
  selectedPostTypes,
  setSelectedTypes,
  setSelectedPostTypes,
  sx = undefined,
}) {
  const { t } = useTranslate();
  const { data: residence } = useCurrentResidenceQuery();

  const postTypes = getVisiblePostTypes(residence.type);

  const handleChangeTypes = (value, filterType) => {
    if (value === ALL) {
      setSelectedTypes(Object.values(FEED_ITEM_TYPE));
      setSelectedPostTypes(postTypes);
      return;
    }

    if (filterType === 'post_type') {
      setSelectedTypes([FEED_ITEM_TYPE.post]);
      setSelectedPostTypes([value]);
      return;
    }

    setSelectedTypes([value]);
    setSelectedPostTypes([]);
  };

  const isAll =
    selectedTypes.length + selectedPostTypes.length >= Object.values(FEED_ITEM_TYPE).length + postTypes.length;

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      bounces={false}
      contentContainerSx={{ px: '$4' }}
      sx={{ mx: '$-4', ...sx }}
    >
      <Chip label={t('common.all')} onPress={() => handleChangeTypes(ALL)} active={isAll} sx={{ mr: '$2' }} />
      {postTypes.map((type) => (
        <Chip
          key={type}
          label={t('constants.PostType', { value: type })}
          active={!isAll && selectedPostTypes.includes(type)}
          onPress={() => handleChangeTypes(type, 'post_type')}
          sx={{ mr: '$2' }}
        />
      ))}
      {Object.values(FEED_ITEM_TYPE)
        .filter((type) => type !== FEED_ITEM_TYPE.post)
        .map((type) => (
          <Chip
            key={type}
            label={t('constants.FeedItemType', { value: type })}
            active={!isAll && selectedTypes.includes(type)}
            onPress={() => handleChangeTypes(type, 'item_type')}
            sx={{ mr: '$2' }}
          />
        ))}
    </ScrollView>
  );
}

FeedMobileFilters.propTypes = {
  selectedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedPostTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedTypes: PropTypes.func.isRequired,
  setSelectedPostTypes: PropTypes.func.isRequired,
  sx: CustomPropTypes.style,
};

export default FeedMobileFilters;
