/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import { IMAGE_MIMES } from '@constants/MimeType';
import ImagesList from './ImagesList';

function ImagesPicker({
  loading = false,
  onSelect,
  onDelete = undefined,
  sx = undefined,
  max = 5,
  images = Object.freeze([]),
  ...props
}) {
  const inputRef = useRef();

  const handlePress = async () => {
    if (loading) {
      return;
    }
    inputRef.current.click();
  };

  const handleChange = (e) => {
    const [file] = e.target.files;
    if (file) {
      onSelect(file);
    }
  };

  return (
    <>
      <input
        type="file"
        accept={IMAGE_MIMES.join(', ')}
        ref={inputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <ImagesList
        onPress={handlePress}
        loading={loading}
        onDelete={onDelete}
        sx={sx}
        max={max}
        images={images}
        {...props}
      />
    </>
  );
}

ImagesPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  sx: CustomPropTypes.style,
  max: PropTypes.number,
  loading: PropTypes.bool,
  images: ImagesList.propTypes.images,
  onDelete: PropTypes.func,
};

export default ImagesPicker;
