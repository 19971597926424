import { useEffect, useState } from 'react';
import { Pressable, Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import { Platform } from 'react-native';
import getDOMElementNumberOfLines from '@utils/getDOMElementNumberOfLines';

function TextCollapse({ collapsed = false, numberOfPreviewLines, sx = undefined, children }) {
  const [open, setOpen] = useState(!collapsed);
  const [lines, setLines] = useState(0);

  useEffect(() => setOpen(!collapsed), [collapsed]);

  const onWebLayout = (e) => {
    setLines(getDOMElementNumberOfLines(e.nativeEvent.target));
  };

  const onTextLayout = (e) => {
    if (lines === 0) {
      setLines(e.nativeEvent.lines.length);
    }
  };

  const toggle = () => setOpen((prev) => !prev);

  const numberOfLines = lines !== 0 && !open ? numberOfPreviewLines : undefined;

  return (
    <View sx={sx}>
      {Platform.OS === 'web' && (
        <View sx={{ position: 'absolute', visibility: 'hidden' }}>
          <Text onLayout={onWebLayout}>{children}</Text>
        </View>
      )}
      <Text numberOfLines={numberOfLines} onTextLayout={onTextLayout}>
        {children}
      </Text>
      {numberOfPreviewLines < lines && !open && (
        <View sx={{ alignSelf: 'flex-end' }}>
          <Pressable onPress={toggle}>
            <Text sx={{ color: '$primary.main' }}>
              <FormattedMessage id="common.seeMore" />
            </Text>
          </Pressable>
        </View>
      )}
    </View>
  );
}

TextCollapse.propTypes = {
  numberOfPreviewLines: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  sx: CustomPropTypes.style,
  collapsed: PropTypes.bool,
};

export default TextCollapse;
