/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import { Platform, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';
import { ActivityIndicator, Image, Ionicons, Pressable, Text, View } from '@ui/atoms';
import { useEffect, useRef, useState } from 'react';
import Slider from '@ui/molecules/Slider';
import getImageSize from '@utils/getImageSize';
import useThemeColor from '@hooks/useThemeColor';
import { FormattedMessage } from 'react-intl';
import useIsMounted from '@hooks/useIsMounted';

function LightboxDialog({ images, defaultIndex = 0, onClose }) {
  const swiperRef = useRef();
  const whiteColor = useThemeColor('$common.white');
  const dimensions = useWindowDimensions();
  const isMounted = useIsMounted();

  const [activeIndex, setActiveIndex] = useState(0);
  const [sizes, setSizes] = useState({});
  const [sliderKey, setSliderKey] = useState(1);

  // Change slider key when sizes/dimensions change to re-render the swiper
  useEffect(() => setSliderKey((prev) => prev + 1), [sizes, dimensions]);

  useEffect(() => {
    // Get image size to respect maximum dimensions; not necessary on web
    if (Platform.OS === 'web') return;

    images.forEach((image) =>
      getImageSize(image.uri)
        .then((imageSize) => {
          if (isMounted()) {
            setSizes((prev) => ({ ...prev, [image.id]: imageSize }));
          }
        })
        .catch(() => {
          if (isMounted()) {
            setSizes((prev) => ({ ...prev, [image.id]: 'failed' }));
          }
        })
    );
  }, [isMounted, images]);

  useEffect(() => {
    const listener = (e) => {
      if (!swiperRef.current) return;
      switch (e.key) {
        case 'ArrowRight':
          swiperRef.current.goToNext();
          break;
        case 'ArrowLeft':
          swiperRef.current.goToPrev();
          break;
      }
    };

    if (Platform.OS === 'web') {
      window.addEventListener('keydown', listener);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('keydown', listener);
      }
    };
  }, [onClose]);

  const totalSlides = images.length;

  return (
    <View
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
        flexGrow: Platform.OS === 'web' ? 'unset' : undefined,
      }}
    >
      <Pressable
        onPress={onClose}
        sx={{
          position: 'absolute',
          bg: '$primary.main',
          borderRadius: 18,
          height: 36,
          width: 36,
          left: '$5',
          top: '$6',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 999,
        }}
      >
        <Ionicons name="close" size={24} sx={{ color: '$common.white' }} />
      </Pressable>
      {totalSlides > 1 && (
        <View
          sx={{
            position: 'absolute',
            bg: '$grey.200',
            borderRadius: 12,
            height: 24,
            width: 50,
            right: '$5',
            top: '$5',
            pt: 1,
            pl: 1,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999,
          }}
        >
          <Text color="white">{`${activeIndex + 1}/${totalSlides}`}</Text>
        </View>
      )}
      <Slider
        key={sliderKey}
        height={dimensions.height}
        defaultIndex={defaultIndex}
        ref={swiperRef}
        onIndexChanged={(index) => setActiveIndex(index)}
      >
        {images.map((image) => {
          if (Platform.OS === 'web') {
            return (
              <Pressable
                onPress={onClose}
                key={image.id}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: dimensions.height,
                  width: dimensions.width,
                }}
              >
                <img
                  src={image.uri}
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    objectFit: 'contain',
                  }}
                />
              </Pressable>
            );
          }

          return (
            <View
              key={image.id + Math.random()}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                height: dimensions.height,
                width: dimensions.width,
              }}
            >
              {sizes[image.id] === 'failed' && (
                <Text sx={{ color: '$red.main' }}>
                  <FormattedMessage id="ui.LightboxDialog.error" />
                </Text>
              )}
              {typeof sizes[image.id] === 'object' && (
                <Image
                  source={{ uri: image.uri }}
                  sx={{
                    width: dimensions.width,
                    height: dimensions.height,
                    maxHeight: sizes[image.id]?.height,
                    maxWidth: sizes[image.id]?.width,
                    resizeMode: 'contain',
                  }}
                />
              )}
              {sizes[image.id] === undefined && <ActivityIndicator color={whiteColor} />}
            </View>
          );
        })}
      </Slider>
    </View>
  );
}

LightboxDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultIndex: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uri: PropTypes.string,
    })
  ).isRequired,
};

export default LightboxDialog;
