import { useState } from 'react';
import { Button, Text, View } from '@ui/atoms';
import PropTypes from 'prop-types';
import useTranslate from '@hooks/useTranslate';
import { Dialog } from '@ui/templates';
import useDialog from '@hooks/useDialog';

function ConfirmAlertDialog({ onConfirm, title, description = null, confirmLabel = null, destructive = false }) {
  const { t } = useTranslate();
  const { close } = useDialog();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onConfirm();
      close();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog onClose={close} size="small">
      <View sx={{ p: '$4' }}>
        <Text variant="subtitle2" sx={{ color: destructive ? '$red.main' : '$primary.main' }}>
          {title}
        </Text>
        {description && <Text sx={{ mt: '$2' }}>{description}</Text>}
        <View sx={{ flexDirection: 'row', gap: '$4', justifyContent: 'space-between', mt: '$6' }}>
          <Button onPress={close} color="grey" variant="outlined" title={t('common.verb.cancel')} />
          <Button
            onPress={handleConfirm}
            loading={loading}
            color={destructive ? 'red' : 'primary'}
            title={confirmLabel ?? t('common.verb.validate')}
          />
        </View>
      </View>
    </Dialog>
  );
}

ConfirmAlertDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmLabel: PropTypes.string,
  destructive: PropTypes.bool,
};

export default ConfirmAlertDialog;
