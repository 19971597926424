import { FormControl } from '@ui/molecules';
import { Platform } from 'react-native';
import { AutoHeightInput, Button, Checkbox, FilePicker, Input, Ionicons, Select, Text, View } from '@ui/atoms';
import { FILE_MIMES } from '@constants/MimeType';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import useToast from '@hooks/useToast';
import useThemeColor from '@hooks/useThemeColor';
import useUploadMutation from '@hooks/mutations/useUploadMutation';
import { useFormik } from 'formik';
import { bool, mixed, object, string } from '@utils/yup';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import useTranslate from '@hooks/useTranslate';
import SURVEY_TYPE from '@constants/SurveyType';
import { array } from 'yup';
import useCreateSurveyMutation from '@hooks/mutations/useCreateSurveyMutation';
import PropTypes from 'prop-types';
import ChoicesList from './ChoicesList';

function SurveyForm({ onSuccess = undefined }) {
  const { t } = useTranslate();
  const toast = useToast();
  const white = useThemeColor('$common.white');

  const { mutateAsync } = useCreateSurveyMutation();

  const [loading, setLoading] = useState(false);

  const form = useFormik({
    validateOnMount: true,
    initialValues: {
      title: '',
      content: '',
      file: null,
      duration: null,
      type: SURVEY_TYPE.choices,
      answerEditable: false,
      realtimeResults: false,

      // Choices survey
      choices: [
        {
          id: '51387c3e-acf8-4ae2-90e6-02afa2937625', // temp ID
          name: '',
          image: null,
        },
        {
          id: '268ae010-7ae3-472f-83f7-53ce9236abac',
          name: '',
          image: null,
        },
      ],
      singleChoice: false,
    },
    validationSchema: object({
      title: string().required(),
      content: string().required(),
      file: mixed().nullable(),
      duration: mixed().required(),
      type: string().required(),
      answerEditable: bool().required(),
      realtimeResults: bool().required(),

      // Choices survey
      choices: mixed().when('type', {
        is: SURVEY_TYPE.choices,
        then: array()
          .of(
            object({
              name: string().required(),
            })
          )
          .min(2)
          .required(),
        otherwise: array().required(),
      }),
      singleChoice: bool().required(),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        setLoading(true);

        const res = await mutateAsync({
          ...data,
          file: data.file?.id || null,
          choices:
            data.type === SURVEY_TYPE.choices
              ? data.choices.map((choice) => ({
                  ...choice,
                  id: undefined,
                  image: choice.image?.id || null,
                }))
              : undefined,
          singleChoice: data.type === SURVEY_TYPE.choices ? data.singleChoice : undefined,
        });

        if ('function' === typeof onSuccess) {
          onSuccess(res.data);
        }
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          toast.error(t('errors.checkData'));
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const { mutate: uploadFile, isLoading: uploadingFile } = useUploadMutation({
    onSuccess: (res) => form.setFieldValue('file', res.data),
    onError: (err) => {
      if (422 === err.response?.status) {
        toast.error(err.response.data.errors?.file[0]);
      } else {
        toast.error(t('errors.api.default'));
      }
    },
  });

  const handleSubmit = async () => {
    await form.submitForm();
    if (!form.isValid) {
      toast.error(t('errors.checkData'));
    }
  };

  const onSubmitEditing = Platform.select({ web: handleSubmit, default: undefined });

  const handleDeleteFile = () => form.setFieldValue('file', null);

  const types = Object.values(SURVEY_TYPE).map((type) => ({
    value: type,
    label: t('constants.SurveyType', { value: type }),
  }));

  const durations = [1, 3, 7, 14, 28].map((duration) => ({
    value: duration,
    label: t('ui.SurveyForm.duration.option', { duration }),
  }));

  return (
    <>
      <FormControl
        label={t('ui.SurveyForm.title')}
        sx={{ mb: '$4' }}
        error={form.touched.title && form.errors.title}
        required
      >
        <Input
          value={form.values.title}
          maxLength={100}
          withCharCounter
          onChangeText={form.handleChange('title')}
          onBlur={form.handleBlur('title')}
          onSubmitEditing={onSubmitEditing}
        />
      </FormControl>
      <FormControl
        label={t('ui.SurveyForm.content')}
        sx={{ mb: '$4' }}
        error={form.touched.content && form.errors.content}
        required
      >
        <AutoHeightInput
          minHeight={100}
          maxLength={600}
          withCharCounter
          value={form.values.content}
          onChangeText={form.handleChange('content')}
          onBlur={form.handleBlur('content')}
        />
      </FormControl>
      <FormControl
        label={t('ui.SurveyForm.duration.label')}
        error={form.touched.duration && form.errors.duration}
        required
        sx={{ mb: '$4' }}
      >
        <Select
          placeholder={{ label: t('common.verb.select'), value: '' }}
          items={durations}
          value={form.values.duration}
          onValueChange={(e) => {
            form.setFieldValue('duration', Number.parseInt(e, 10));
          }}
        />
      </FormControl>
      <FormControl
        label={t('ui.SurveyForm.file')}
        sx={{ mb: '$4' }}
        labelProps={{ variant: 'subtitle', sx: { color: '$primary.main' } }}
        error={form.touched.file && form.errors.file}
      >
        <FilePicker
          file={form.values.file}
          loading={uploadingFile}
          onChange={uploadFile}
          mimeTypes={FILE_MIMES}
          onDelete={handleDeleteFile}
          error={form.errors.file && form.touched.file}
        />
      </FormControl>
      <Text variant="subtitle2" sx={{ mt: '$2', mb: '$4', color: '$primary.main' }}>
        <FormattedMessage id="ui.SurveyForm.settings" />
      </Text>
      <FormControl
        label={t('ui.SurveyForm.type')}
        error={form.touched.type && form.errors.type}
        required
        sx={{ mb: '$4' }}
      >
        <Select
          placeholder={{ label: t('common.verb.select'), value: '' }}
          items={types}
          value={form.values.type}
          onValueChange={(e) => form.setFieldValue('type', e)}
        />
      </FormControl>
      {SURVEY_TYPE.choices === form.values.type && (
        <View sx={{ mb: '$4' }}>
          <ChoicesList
            choices={form.values.choices}
            errors={form.errors?.choices}
            touched={form.touched?.choices}
            setValues={form.setValues}
          />
        </View>
      )}
      <View sx={{ mb: '$4', mt: '$2' }}>
        <Checkbox
          checked={form.values.answerEditable}
          onChange={(e) => form.setFieldValue('answerEditable', e)}
          label={
            <Text variant="subtitle">
              <FormattedMessage id="ui.SurveyForm.answerEditable.label" />
            </Text>
          }
        />
        <Text sx={{ mt: '$2' }}>
          <FormattedMessage id="ui.SurveyForm.answerEditable.help" />
        </Text>
      </View>
      {SURVEY_TYPE.choices === form.values.type && (
        <View sx={{ mb: '$4' }}>
          <Checkbox
            checked={form.values.singleChoice}
            onChange={(e) => form.setFieldValue('singleChoice', e)}
            label={
              <Text variant="subtitle">
                <FormattedMessage id="ui.SurveyForm.singleChoice.label" />
              </Text>
            }
          />
          <Text sx={{ mt: '$2' }}>
            <FormattedMessage id="ui.SurveyForm.singleChoice.help" />
          </Text>
        </View>
      )}
      <View sx={{ mb: '$4' }}>
        <Checkbox
          checked={form.values.realtimeResults}
          onChange={(e) => form.setFieldValue('realtimeResults', e)}
          label={
            <Text variant="subtitle">
              <FormattedMessage id="ui.SurveyForm.realtimeResults.label" />
            </Text>
          }
        />
        <Text sx={{ mt: '$2' }}>
          <FormattedMessage id="ui.SurveyForm.realtimeResults.help" />
        </Text>
      </View>
      <View sx={{ mt: '$2' }}>
        <Button
          color="primary"
          title={t('common.verb.publish')}
          onPress={() => handleSubmit()}
          startIcon={<Ionicons name="ios-paper-plane-outline" size={15} color={white} />}
          loading={loading}
        />
      </View>
    </>
  );
}

SurveyForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default SurveyForm;
