import { Flex, Ionicons, Pressable, Text, UserAvatar, View } from '@ui/atoms';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { useSx } from 'dripsy';
import RESIDENCE_USER_ROLE from '@constants/ResidenceUserRole';
import useOpenUserProfile from '@hooks/useOpenUserProfile';

function Neighbor({ contact, sx = undefined, expanded = false }) {
  const sxfi = useSx();
  const openUserProfile = useOpenUserProfile();

  const [isExpanded, setExpanded] = useState(expanded);

  const handlePress = () => {
    openUserProfile(contact.id);
  };

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={sxfi({ p: '$3', borderRadius: '$1', borderWidth: 1, borderColor: '$transparent.primary.50', ...sx })}
    >
      <Flex alignItems="center">
        <UserAvatar sx={{ mr: '$2' }} size="large" user={contact} />
        <View>
          <Text variant="subtitle" sx={{ color: '$primary.main' }}>
            {contact.fullname}
          </Text>
          {(contact.email || contact.flatNumber) && (
            <Text sx={{ fontSize: 12 }}>{contact.email || contact.flatNumber}</Text>
          )}
        </View>
      </Flex>
      {!expanded && contact.description && (
        <Pressable onPress={() => setExpanded((prev) => !prev)}>
          <Flex alignItems="center" sx={{ mt: '$3' }}>
            <Text>
              <FormattedMessage
                id={isExpanded ? 'ui.ContactsWidget.Contact.showLess' : 'ui.ContactsWidget.Contact.showMore'}
              />
            </Text>
            <Ionicons name={isExpanded ? 'chevron-up' : 'chevron-down'} sx={{ ml: '$1', color: '$text.primary' }} />
          </Flex>
        </Pressable>
      )}
      {isExpanded && contact.description && (
        <View sx={{ mt: '$3' }}>
          <Text>{contact.description}</Text>
        </View>
      )}
    </TouchableOpacity>
  );
}

Neighbor.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
    fullname: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.shape({
      original: PropTypes.string,
      small: PropTypes.string,
    }),
    description: PropTypes.string,
    flatNumber: PropTypes.string,
    role: PropTypes.oneOf(Object.values(RESIDENCE_USER_ROLE)),
  }).isRequired,
  sx: CustomPropTypes.style,
  expanded: PropTypes.bool,
};

export default Neighbor;
