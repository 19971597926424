import Text from '@ui/atoms/Text';
import View from '@ui/atoms/View';
import PropTypes from 'prop-types';

function ProgressBar({ label, value, variant = 'grey' }) {
  return (
    <View
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        height: 48,
        width: '100%',
        borderRadius: 50,
        my: '$2',
        backgroundColor: variant === 'white' ? '$common.white' : '$grey.100',
        boxShadow: variant === 'white' ? 'sm' : undefined,
      }}
    >
      <View
        sx={{
          height: 48,
          borderRadius: 50,
          position: 'absolute',
          backgroundColor: '$transparent.primary.50',
          zIndex: 20,
          width: `${value}%`,
        }}
      />
      <Text sx={{ color: '$primary.main', ml: '$5' }}>{label}</Text>
      <Text sx={{ color: '$primary.main', mr: '$5' }}>{value.toFixed(1)} %</Text>
    </View>
  );
}

ProgressBar.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['grey', 'white']),
};

export default ProgressBar;
