import { useState } from 'react';
import { Button, Checkbox, Image, Ionicons, Pressable, Text, View } from '@ui/atoms';
import useTranslate from '@hooks/useTranslate';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import noImage from '@src/assets/no-image.png';
import { LightboxDialog } from '@ui/molecules';
import CustomPropTypes from '@utils/CustomPropTypes';
import useDialog from '@hooks/useDialog';

function ChoicesForm({ survey, onSubmit, loading = false }) {
  const { t } = useTranslate();
  const { open } = useDialog();

  const [selected, setSelected] = useState([]);

  const handlePressImage = (image) => {
    open(LightboxDialog, {
      images: [
        {
          id: image.id,
          uri: image.large,
        },
      ],
    });
  };

  const handleSubmit = () => onSubmit({ choices: selected });

  const handleChange = (choiceId) => {
    if (survey.singleChoice) {
      setSelected((prev) => (prev === choiceId ? [] : [choiceId]));
      return;
    }
    setSelected((prev) =>
      prev.includes(choiceId) ? prev.filter((choice) => choice !== choiceId) : [...prev, choiceId]
    );
  };

  const hasImages = survey.choices.some((option) => option.image?.small);

  return (
    <View>
      <Text sx={{ mb: '$2' }}>
        <FormattedMessage id="ui.Survey.ChoicesForm.title" values={{ value: survey.singleChoice }} />
      </Text>
      {survey.choices?.map((option) => (
        <View
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            my: '$1',
          }}
          key={option?.id}
        >
          {option?.image?.small && (
            <Pressable onPress={() => handlePressImage(option?.image)} sx={{ borderRadius: '$1', overflow: 'hidden' }}>
              <Image
                sx={{
                  height: 70,
                  width: 70,
                  alignItems: 'center',
                  justifyContent: 'center',
                  resizeMode: 'contain',
                  bg: '$grey.300',
                }}
                source={{ uri: option?.image.small }}
              />
            </Pressable>
          )}
          {hasImages && !option?.image?.small && (
            <View sx={{ borderRadius: '$1', overflow: 'hidden' }}>
              <Image
                sx={{
                  height: 70,
                  width: 70,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                source={noImage}
              />
            </View>
          )}
          <View sx={{ flex: 1, ml: hasImages ? '$4' : 0 }}>
            <Checkbox
              label={option.name}
              onChange={() => handleChange(option.id)}
              checked={selected.includes(option.id)}
              key={option.id}
              sx={{ flexDirection: 'row-reverse', justifyContent: 'space-between' }}
              checkboxSx={{ ml: '$2', mr: 0 }}
            />
          </View>
        </View>
      ))}
      <View sx={{ mt: '$6' }}>
        <Button
          color="primary"
          title={t('ui.Survey.ChoicesForm.validate')}
          startIcon={<Ionicons name="checkmark" size={24} sx={{ color: '$common.white' }} />}
          disabled={selected.length === 0}
          onPress={handleSubmit}
          loading={loading}
        />
      </View>
    </View>
  );
}

ChoicesForm.propTypes = {
  survey: CustomPropTypes.survey.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ChoicesForm;
