import { Flex, Pressable, Skeleton, Text, View } from '@ui/atoms';
import useCommentsQuery from '@hooks/queries/useCommentsQuery';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomPropTypes from '@utils/CustomPropTypes';
import Comment from '@ui/organisms/Comments/Comment';
import PropTypes from 'prop-types';
import COMMENTABLE_MODEL from '@constants/CommentableModel';

function Comments({ model, collapsed = false, onPressSeeMore = undefined, sx = undefined }) {
  const { data, isLoading } = useCommentsQuery(model.type, model.id);

  const [open, setOpen] = useState(!collapsed);

  useEffect(() => setOpen(!collapsed), [collapsed]);

  if (isLoading) {
    return (
      <Flex alignItems="center" sx={sx}>
        <Skeleton height={32} width={32} radius={16} sx={{ mr: '$4' }} />
        <View sx={{ flex: 1 }}>
          <Skeleton height={48} width="100%" />
        </View>
      </Flex>
    );
  }

  const handlePressSeeMore = (e) => {
    if (typeof onPressSeeMore !== 'function') {
      return setOpen(true);
    }
    return onPressSeeMore(e);
  };

  const visibleComments = open ? data.items : data.items.slice(0, 2);

  if (visibleComments.length === 0) {
    return null;
  }

  return (
    <View sx={sx}>
      {visibleComments.map((comment, index) => (
        <Comment
          key={comment.id}
          comment={comment}
          collapsed={collapsed}
          sx={{ mb: index === visibleComments.length - 1 ? 0 : '$4' }}
        />
      ))}
      {data.items.length > 2 && !open && (
        <Pressable onPress={handlePressSeeMore} sx={{ mt: '$4' }}>
          <Text variant="subtitle" sx={{ color: '$primary.main', textAlign: 'center' }}>
            <FormattedMessage id="ui.Comments.open" />
          </Text>
        </Pressable>
      )}
    </View>
  );
}

Comments.propTypes = {
  model: PropTypes.exact({
    type: PropTypes.oneOf(Object.values(COMMENTABLE_MODEL)),
    id: CustomPropTypes.uuid,
  }).isRequired,
  collapsed: PropTypes.bool,
  onPressSeeMore: PropTypes.func,
  sx: CustomPropTypes.style,
};

export default Comments;
