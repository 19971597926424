import { Flex, Skeleton, View } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';

function NeighborSkeleton({ sx = undefined, expanded = false }) {
  return (
    <View sx={{ p: '$3', borderRadius: '$1', borderWidth: 1, borderColor: '$transparent.primary.50', ...sx }}>
      <Flex alignItems="center">
        <Skeleton height={50} width={50} radius={25} sx={{ mr: '$2' }} />
        <View>
          <Skeleton width={140} height={14} sx={{ mb: '$1' }} />
          <Skeleton width={90} height={14} />
        </View>
      </Flex>
      {expanded || <Skeleton width={100} height={12} sx={{ mt: '$3' }} />}
      {expanded && (
        <View sx={{ mt: '$3' }}>
          <Skeleton width="100%" height={80} sx={{ mt: '$3' }} />
        </View>
      )}
    </View>
  );
}

NeighborSkeleton.propTypes = {
  sx: CustomPropTypes.style,
  expanded: PropTypes.bool,
};

export default NeighborSkeleton;
