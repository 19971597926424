/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import Input, { SIZES } from '@ui/atoms/Input';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

const AutoHeightInput = React.forwardRef(
  (
    { onHeightChange = undefined, minHeight = 0, maxHeight = undefined, size = 'medium', sx = undefined, ...props },
    ref
  ) => {
    const [height, setHeight] = useState(Math.max(SIZES[size], minHeight));
    useEffect(() => {
      if ('function' === typeof onHeightChange) {
        onHeightChange(height);
      }
    }, [onHeightChange, height]);

    const calculateHeight = (newHeight) => {
      // eslint-disable-next-line no-param-reassign
      newHeight = maxHeight ? Math.min(newHeight, maxHeight) : newHeight;

      if (newHeight < minHeight) {
        // eslint-disable-next-line no-param-reassign
        newHeight = minHeight;
      }

      return newHeight;
    };

    return (
      <Input
        ref={ref}
        multiline
        size={size}
        onContentSizeChange={Platform.select({
          default: (event) => {
            const newHeight = calculateHeight(event.nativeEvent.contentSize.height + 24);
            setHeight(newHeight);
          },
          web: undefined,
        })}
        onChange={Platform.select({
          default: undefined,
          web: ({ target }) => {
            // eslint-disable-next-line no-param-reassign
            target.style.height = 0;

            // over height is to avoid scroll
            const newHeight = calculateHeight(target.scrollHeight + 3);

            // eslint-disable-next-line no-param-reassign
            target.style.height = `${newHeight}px`;

            setHeight(newHeight);
          },
        })}
        sx={{ height, ...sx }}
        {...props}
      />
    );
  }
);

AutoHeightInput.propTypes = {
  onHeightChange: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'small']),
  sx: CustomPropTypes.style,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.number,
};

export default AutoHeightInput;
