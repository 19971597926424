import PropTypes from 'prop-types';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';

/**
 * Component used to translate validation errors coming from formik and yup
 *
 * @param {string|{key: string, values: object}|null|boolean} error
 */
function FormError({ error = null }) {
  if (!error) {
    return null;
  }

  return (
    <Text sx={{ color: '$red.main' }}>
      {typeof error === 'string' ? error : <FormattedMessage id={error.key} values={error.values} />}
    </Text>
  );
}

FormError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ key: PropTypes.string, values: PropTypes.object }),
    PropTypes.bool,
  ]),
};

export default FormError;
