/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import View from './View';

function Flex({
  direction = 'row',
  children,
  alignItems = undefined,
  justifyContent = undefined,
  wrap = false,
  sx = undefined,
  ...props
}) {
  return (
    <View
      sx={{
        flexDirection: direction,
        alignItems,
        justifyContent,
        ...(wrap && { flexWrap: 'wrap' }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </View>
  );
}

Flex.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  alignItems: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  justifyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  wrap: PropTypes.bool,
  sx: CustomPropTypes.style,
};

export default Flex;
