import format from 'date-fns/format';
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc';
import { Platform, TouchableOpacity } from 'react-native';
import { Flex, Text, View } from '@ui/atoms/index';
import { FormattedMessage } from 'react-intl';
import * as Calendar from 'expo-calendar';
import PropTypes from 'prop-types';
import useTranslate from '@hooks/useTranslate';
import useOptionsActionSheet from '@hooks/useOptionsActionSheet';
import useToast from '@hooks/useToast';

function CalendarDropdown({ children }) {
  return (
    <Flex>
      {children.map((link) => (
        <Text
          key={link}
          variant="subtitle"
          sx={{ mt: '$1', mr: '$2', fontSize: 12, color: '$primary.main', textDecoration: 'none' }}
        >
          {link}
        </Text>
      ))}
    </Flex>
  );
}

CalendarDropdown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string })).isRequired,
};

function CalendarButton({ onClick, children }) {
  return (
    <View onClick={onClick}>
      <Text variant="subtitle" sx={{ fontSize: 12, color: '$primary.main', cursor: 'pointer' }}>
        {children}
      </Text>
    </View>
  );
}

CalendarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};

const AddToCalendarDropdown = AddToCalendarHOC(CalendarButton, CalendarDropdown);

function AddToCalendar({
  title,
  startAt,
  endAt,
  timezone,
  location = '', // Valeur par défaut
  description = '', // Valeur par défaut
}) {
  const { t } = useTranslate();
  const toast = useToast();
  const openOptionsActionSheet = useOptionsActionSheet();

  const handleSelectCalendar = async () => {
    await Calendar.requestCalendarPermissionsAsync();
    const calendars = (await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT)).filter(
      (item) => true === item.allowsModifications
    );

    openOptionsActionSheet(
      calendars.map((calendar) => ({
        label: calendar.title,
        onPress: async () => {
          const { status } = await Calendar.requestCalendarPermissionsAsync();

          if ('granted' === status) {
            await Calendar.createEventAsync(calendar.id, {
              title,
              startDate: startAt,
              endDate: endAt,
              notes: description,
              location,
              timeZone: timezone,
            });

            toast.success(t('ui.AddToCalendar.success'));
          }
        },
      }))
    );
  };

  return 'web' !== Platform.OS ? (
    <TouchableOpacity onPress={handleSelectCalendar}>
      <Text variant="subtitle" sx={{ mt: '$1', fontSize: 12, color: '$primary.main' }}>
        <FormattedMessage id="ui.AddToCalendar.button" />
      </Text>
    </TouchableOpacity>
  ) : (
    <AddToCalendarDropdown
      event={{
        title,
        location,
        description,
        timezone,
        duration: (endAt.getTime() - startAt.getTime()) / 1000 / 60 / 60,
        startDatetime: format(startAt, "yyyyMMdd'T'HHmmssxxx"),
        endDatetime: format(endAt, "yyyyMMdd'T'HHmmssxxx"),
      }}
      items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.OUTLOOK]}
      buttonText={t('ui.AddToCalendar.button')}
      linkProps={{ textDecoration: 'none' }}
    />
  );
}

AddToCalendar.propTypes = {
  title: PropTypes.string.isRequired,
  startAt: PropTypes.instanceOf(Date).isRequired,
  endAt: PropTypes.instanceOf(Date).isRequired,
  timezone: PropTypes.string.isRequired,
  location: PropTypes.string,
  description: PropTypes.string,
};

export default AddToCalendar;
