
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        import { Button, Hidden, Ionicons, Pressable, Text, View } from '@ui/atoms';
import { Panel } from '@ui/molecules';

  const LockIcon = __ReactNativeSvgLoader(import('@src/assets/icons/lock.svg'));
  ;

  const BlockIcon = __ReactNativeSvgLoader(import('@src/assets/icons/block.svg'));
  ;
import useBreakpointName from '@hooks/useBreakpointName';
import useTranslate from '@hooks/useTranslate';
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';
import CustomPropTypes from '@utils/CustomPropTypes';
import useThemeColor from '@hooks/useThemeColor';
import useConfirmAlert from '@hooks/useConfirmAlert';
import useDeleteMeMutation from '@hooks/mutations/useDeleteMeMutation';
import { logout } from '@src/stores/authStore';
import useToast from '@hooks/useToast';

function UserSettings({ sx = undefined }) {
  const { t } = useTranslate();
  const toast = useToast();
  const breakpoint = useBreakpointName();
  const { navigate } = useNavigation();
  const destructiveColor = useThemeColor('$red.main');
  const showConfirmAlert = useConfirmAlert();

  const { mutateAsync } = useDeleteMeMutation({
    onError: () => toast.error(t('errors.api.default')),
    onSuccess: () => {
      logout();
      toast.success(t('ui.UserSettings.confirmDelete.success'));
    },
  });

  const handleDeleteAccount = () => {
    showConfirmAlert({
      title: t('ui.UserSettings.confirmDelete.title'),
      description: t('ui.UserSettings.confirmDelete.description'),
      destructive: true,
      confirmLabel: t('ui.UserSettings.confirmDelete.confirm'),
      onConfirm: () => mutateAsync(),
    });
  };

  const iconSize = ['sm', 'md', 'lg', 'xl'].includes(breakpoint) ? 28 : 15;

  const items = [
    {
      id: 1,
      icon: <Ionicons name="notifications-outline" size={iconSize} />,
      title: t('ui.UserSettings.notifications'),
      onPress: () => navigate(SCREENS.notificationsSettings),
      button: t('common.verb.edit'),
    },
    {
      id: 2,
      icon: <LockIcon width={iconSize} height={iconSize} />,
      title: t('ui.UserSettings.password'),
      onPress: () => navigate(SCREENS.editPassword),
      button: t('common.verb.edit'),
    },
    {
      id: 3,
      icon: <BlockIcon width={iconSize} height={iconSize} />,
      title: t('ui.UserSettings.blockedUsers'),
      onPress: () => navigate(SCREENS.blockedUsers),
      button: t('common.verb.edit'),
    },
    {
      id: 4,
      icon: <Ionicons name="trash-outline" color={destructiveColor} size={iconSize} />,
      title: t('ui.UserSettings.deleteAccount'),
      onPress: handleDeleteAccount,
      button: t('common.verb.delete'),
      destructive: true,
    },
  ];

  return (
    <View sx={sx}>
      {items.map((item, index) => {
        return (
          <Panel
            key={item.id}
            sx={{
              width: '100%',
              mt: 0 === index ? 0 : '$4',
              px: ['$4', null, '$6'],
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onPress={item.onPress}
            as={Pressable}
            shadow
          >
            <View sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <View
                sx={{
                  width: [35, 60],
                  height: [35, 60],
                  backgroundColor: item.destructive ? '$transparent.red.10' : '$grey.300',
                  borderRadius: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {item.icon}
              </View>
              <Text sx={{ ml: '$4', color: item.destructive ? '$red.main' : '$text.primary' }}>{item.title}</Text>
            </View>
            <Hidden only="xs">
              <Button
                sx={{ width: 120 }}
                color={item.destructive ? 'red' : 'primary'}
                title={item.button}
                onPress={item.onPress}
              />
            </Hidden>
          </Panel>
        );
      })}
    </View>
  );
}

UserSettings.propTypes = {
  sx: CustomPropTypes.style,
};

export default UserSettings;

        