/* eslint-disable react/no-unstable-nested-components */
import { Text, Button, Input, Flex, Ionicons, View, Pressable } from '@ui/atoms';
import { FormControl } from '@ui/molecules';
import AvatarPicker from '@ui/organisms/AvatarPicker';
import useTranslate from '@hooks/useTranslate';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import { useCallback, useRef } from 'react';
import { Platform } from 'react-native';
import PasswordInput from '@ui/atoms/PasswordInput';
import useOpenGtu from '@hooks/useOpenGtu';
import useOpenPrivacyPolicy from '@hooks/useOpenPrivacyPolicy';

const MAX_FORM_WIDTH = 496;

function RegisterForm({ form, loading = false, uploading = false, onSubmit, onFileChange, withoutJoinCode = false }) {
  const { t } = useTranslate();
  const openGtu = useOpenGtu();
  const openPrivacy = useOpenPrivacyPolicy();

  const avatarPickerRef = useRef();

  const handleChangeAvatar = useCallback((file) => onFileChange(file, 'avatar'), [onFileChange]);

  const onSubmitEditing = Platform.select({ web: onSubmit, default: undefined });

  return (
    <>
      {withoutJoinCode || (
        <FormControl
          label={t('ui.RegisterForm.joinCode.label')}
          error={form.touched.joinCode && form.errors.joinCode}
          required
          sx={{ mb: '$5' }}
        >
          <Input
            value={form.values.joinCode}
            onChangeText={form.handleChange('joinCode')}
            onBlur={form.handleBlur('joinCode')}
            onSubmitEditing={onSubmitEditing}
            keyboardType="number-pad"
            maxLength={5}
          />
        </FormControl>
      )}
      <Flex justifyContent="space-between" wrap>
        <FormControl
          label={t('ui.RegisterForm.firstname.label')}
          sx={{ width: ['100%', MAX_FORM_WIDTH / 2 - 8] }}
          error={form.touched.firstname && form.errors.firstname}
          required
        >
          <Input
            autoComplete="name"
            textContentType="name"
            value={form.values.firstname}
            onChangeText={form.handleChange('firstname')}
            onBlur={form.handleBlur('firstname')}
            onSubmitEditing={onSubmitEditing}
          />
        </FormControl>
        <FormControl
          label={t('ui.RegisterForm.lastname.label')}
          sx={{ width: ['100%', MAX_FORM_WIDTH / 2 - 8], mt: ['$5', 0] }}
          error={form.touched.lastname && form.errors.lastname}
          required
        >
          <Input
            autoComplete="name-given"
            textContentType="givenName"
            value={form.values.lastname}
            onChangeText={form.handleChange('lastname')}
            onBlur={form.handleBlur('lastname')}
            onSubmitEditing={onSubmitEditing}
          />
        </FormControl>
      </Flex>
      <FormControl
        label={t('ui.RegisterForm.email.label')}
        sx={{ mt: '$5' }}
        error={form.touched.email && form.errors.email}
        required
      >
        <Input
          type="email"
          autoComplete="email"
          keyboardType="email-address"
          textContentType="emailAddress"
          autoCapitalize="none"
          value={form.values.email}
          onChangeText={form.handleChange('email')}
          onBlur={form.handleBlur('email')}
          onSubmitEditing={onSubmitEditing}
        />
      </FormControl>
      {'web' !== Platform.OS && (
        <>
          {/*     Empty TextInput solve secure text input locale problem See:
          https://github.com/facebook/react-native/issues/21572#issuecomment-536117326 */}
          <Input value="" secureTextEntry sx={{ zIndex: 0, height: 0, display: 'none' }} />
          <Input value="" secureTextEntry sx={{ zIndex: 0, height: 0, display: 'none' }} />
        </>
      )}
      <FormControl
        sx={{ mt: '$5' }}
        error={form.touched.password && form.errors.password}
        required
        label={t('ui.RegisterForm.password.label')}
      >
        <PasswordInput
          valid={form.touched.password && !form.errors.password}
          autoComplete="password-new"
          autoCapitalize="none"
          textContentType="newPassword"
          value={form.values.password}
          onChangeText={form.handleChange('password')}
          returnKeyType="next"
          returnKeyLabel={t('common.next')}
          blurOnSubmit={false}
          onSubmitEditing={onSubmitEditing}
          onBlur={form.handleBlur('password')}
          passwordRules="minlength: 8; required: lower; required: upper; required: digit;"
        />
      </FormControl>
      <FormControl
        label={t('ui.RegisterForm.passwordConfirmation.label')}
        sx={{ mt: '$5' }}
        error={form.touched.passwordConfirmation && form.errors.passwordConfirmation}
        required
      >
        <PasswordInput
          valid={form.touched.passwordConfirmation && !form.errors.passwordConfirmation}
          autoCapitalize="none"
          autoComplete="password-new"
          textContentType="newPassword"
          value={form.values.passwordConfirmation}
          onChangeText={form.handleChange('passwordConfirmation')}
          returnKeyType="done"
          returnKeyLabel={t('common.verb.done')}
          blurOnSubmit={false}
          onSubmitEditing={onSubmitEditing}
          onBlur={form.handleBlur('passwordConfirmation')}
          passwordRules="minlength: 8; required: lower; required: upper; required: digit;"
        />
      </FormControl>
      <Text sx={{ mt: '$5' }}>
        <FormattedMessage id="ui.RegisterForm.avatar.title" />
      </Text>
      <View
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          pt: '$2',
        }}
      >
        <AvatarPicker
          onChange={handleChangeAvatar}
          avatar={form.values.avatar?.preview}
          loading={uploading}
          ref={avatarPickerRef}
        />
        <Pressable onPress={() => avatarPickerRef.current?.handlePress()}>
          <Text
            sx={{
              ml: '$5',
              textDecorationLine: 'underline',
              textDecorationStyle: 'solid',
            }}
          >
            <FormattedMessage id="ui.RegisterForm.avatar.label" />
          </Text>
        </Pressable>
      </View>

      <Text sx={{ mt: '$5' }}>
        <FormattedMessage
          id="ui.RegisterForm.acceptTerms"
          values={{
            gtuLink: (chunk) => (
              <Text
                onPress={() => openGtu()}
                sx={{
                  color: '$primary.main',
                  textDecorationLine: 'underline',
                  textDecorationStyle: 'solid',
                }}
              >
                {chunk}
              </Text>
            ),
            privacyLink: (chunk) => (
              <Text
                onPress={() => openPrivacy()}
                sx={{
                  color: '$primary.main',
                  textDecorationLine: 'underline',
                  textDecorationStyle: 'solid',
                }}
              >
                {chunk}
              </Text>
            ),
          }}
        />
      </Text>
      <View sx={{ mt: '$5' }}>
        <Button
          color="primary"
          title={t('ui.RegisterForm.submit')}
          startIcon={<Ionicons name="log-in-outline" size={24} sx={{ color: '$common.white' }} />}
          onPress={onSubmit}
          loading={loading}
        />
      </View>
    </>
  );
}

RegisterForm.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      avatar: CustomPropTypes.apiFile,
      joinCode: PropTypes.string,
      lastname: PropTypes.string,
      firstname: PropTypes.string,
      email: PropTypes.string,
      password: PropTypes.string,
      passwordConfirmation: PropTypes.string,
    }),
    touched: CustomPropTypes.formTouched,
    errors: CustomPropTypes.formErrors,
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
  loading: PropTypes.bool,
  withoutJoinCode: PropTypes.bool,
};

export default RegisterForm;
