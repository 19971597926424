import { Flex, Ionicons, Text, UserAvatar, View } from '@ui/atoms';
import { Platform, TouchableWithoutFeedback } from 'react-native';
import useTranslate from '@hooks/useTranslate';
import useRelativeDate from '@hooks/useRelativeDate';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import useDeviceDimensions from '@hooks/useDeviceDimensions';
import useOpenUserProfile from '@hooks/useOpenUserProfile';
import { TextCollapse } from '@ui/molecules';
import useOptionsActionSheet from '@hooks/useOptionsActionSheet';
import useConfirmAlert from '@hooks/useConfirmAlert';
import useMeQuery from '@hooks/queries/useMeQuery';
import { useCallback, useMemo, useState } from 'react';
import { Dropdown } from '@ui/templates';
import useDeleteCommentMutation from '@hooks/mutations/useDeleteCommentMutation';
import useBlockUserMutation from '@hooks/mutations/useBlockUserMutation';

function Comment({ comment, collapsed: passedCollapsed = false, sx = undefined }) {
  const { t } = useTranslate();
  const formatRelative = useRelativeDate();
  const dimensions = useDeviceDimensions();
  const openUserProfile = useOpenUserProfile();
  const openOptionsActionSheet = useOptionsActionSheet();
  const showConfirmAlert = useConfirmAlert();

  const { data: me } = useMeQuery();

  const { mutateAsync: deleteAsync } = useDeleteCommentMutation(comment.id, comment.model.type, comment.model.id);
  const { mutateAsync: blockUserAsync } = useBlockUserMutation(comment.user.id);

  const [collapsed, setCollapsed] = useState(passedCollapsed);

  const isSmallDevice = dimensions.width <= 320;

  const handleOpenProfile = () => openUserProfile(comment.user.id);

  const handleBlockUser = useCallback(() => {
    showConfirmAlert({
      title: t('ui.Comments.Comment.confirmBlockUser.title', { firstname: comment.user.firstname }),
      description: t('ui.Comments.Comment.confirmBlockUser.description'),
      destructive: true,
      confirmLabel: t('ui.Comments.Comment.confirmBlockUser.confirm'),
      onConfirm: () => blockUserAsync(),
    });
  }, [blockUserAsync, comment.user.firstname, showConfirmAlert, t]);

  const handleDelete = useCallback(() => {
    showConfirmAlert({
      title: t('ui.Comments.Comment.confirmDelete.title'),
      description: t('ui.Comments.Comment.confirmDelete.description'),
      destructive: true,
      confirmLabel: t('ui.Comments.Comment.confirmDelete.confirm'),
      onConfirm: () => deleteAsync(),
    });
  }, [deleteAsync, showConfirmAlert, t]);

  const options = useMemo(
    () => [
      {
        icon: <Ionicons name="eye-off" size={18} />,
        label: t('ui.Comments.Comment.block', { firstname: comment.user.firstname }),
        onPress: handleBlockUser,
        visible: me.id !== comment.user.id,
      },
      {
        icon: <Ionicons name="trash" size={18} sx={{ color: '$red.main' }} />,
        label: t('ui.Comments.Comment.delete'),
        visible: comment.permissions.delete,
        onPress: handleDelete,
        destructive: true,
      },
    ],
    [comment, handleBlockUser, handleDelete, me, t]
  );

  const handleLongPress = Platform.select({
    web: undefined,
    default: () => openOptionsActionSheet(options),
  });

  const handlePress = Platform.select({
    web: undefined,
    default: () => setCollapsed(false),
  });

  const Component = Platform.OS === 'web' ? View : TouchableWithoutFeedback;

  return (
    <Flex key={comment.id} sx={sx}>
      <UserAvatar onPress={handleOpenProfile} sx={{ mr: '$4' }} size="medium" user={comment.user} />
      <View sx={{ flex: 1 }}>
        <Component onPress={handlePress} onLongPress={handleLongPress}>
          <View sx={{ backgroundColor: '$grey.200', borderRadius: '$2', padding: '$2' }}>
            <Flex justifyContent="flex-end" sx={{ height: 20 }}>
              <Text sx={{ fontSize: 12, fontWeight: 'medium' }}>
                {isSmallDevice ? comment.user.firstname : comment.user.fullname}
              </Text>
              <Text sx={{ fontSize: 12, color: '$primary.main', ml: 'auto' }}>
                {formatRelative(new Date(comment.createdAt))}
              </Text>
              {comment.createdAt !== comment.updatedAt && (
                <Text sx={{ ml: '$1', fontSize: 10, color: '$grey.600' }}>
                  ({t('common.updated', { conjugation: 'masculine' })})
                </Text>
              )}
              {Platform.OS === 'web' && (
                <Dropdown width={250} placement="bottom-end" options={options}>
                  <View
                    sx={{
                      height: 40,
                      width: 40,
                      top: -11,
                      ml: '$2',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Ionicons name="ellipsis-horizontal-sharp" size={24} sx={{ color: '$text.primary' }} />
                  </View>
                </Dropdown>
              )}
            </Flex>
            <View>
              <TextCollapse
                collapsed={collapsed}
                numberOfPreviewLines={Platform.select({ web: 4, default: 2 })}
                sx={{ mt: '$1' }}
              >
                {comment.comment}
              </TextCollapse>
            </View>
          </View>
        </Component>
      </View>
    </Flex>
  );
}

Comment.propTypes = {
  comment: CustomPropTypes.comment.isRequired,
  collapsed: PropTypes.bool,
  sx: CustomPropTypes.style,
};

export default Comment;
