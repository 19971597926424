import { useRef } from 'react';
import { View } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';
import { Panel } from '@ui/molecules';
import PostCardSkeleton from '@ui/organisms/PostCardSkeleton';
import Comments from '@ui/organisms/Comments/Comments';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import usePostQuery from '@hooks/queries/usePostQuery';
import AddComment from '@ui/organisms/AddComment';
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';
import COMMENTABLE_MODEL from '@constants/CommentableModel';
import Post from '@ui/organisms/Post';
import POST_TYPE, { isKnownPostType } from '@constants/PostType';

const { Header, Content, Toolbar } = Post;

function PostCard({
  id,
  sx = undefined,
  full = false,
  visible = false,
  borderless = false,
  onNotFoundError = undefined,
}) {
  const { navigate } = useNavigation();

  const commentsRef = useRef();
  const commentInputRef = useRef();

  const {
    data: post,
    isLoading,
    isFetched,
    isError,
  } = usePostQuery(id, {
    enabled: visible,
    onError: (err) => {
      if (err.response?.status === 404 && typeof onNotFoundError === 'function') {
        onNotFoundError(err);
      }
    },
  });

  if (isError) {
    return null;
  }

  if (isLoading || !isFetched) {
    return <PostCardSkeleton sx={sx} borderless={borderless} />;
  }

  if (!isKnownPostType(post.type)) {
    return null;
  }

  const handlePressSeeMore = Platform.select({
    web: undefined,
    default: () => navigate(SCREENS.post, { id, shouldScrollComments: true }),
  });

  const handlePressCommentsCounter = () => {
    if (Platform.OS === 'web') {
      commentsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    navigate(SCREENS.post, { id, shouldScrollComments: true });
  };

  const handlePressCommentButton = () => {
    if (Platform.OS === 'web') {
      commentInputRef.current.focus();
      return;
    }
    navigate(SCREENS.post, { id, shouldFocusCommentInput: true });
  };

  const Root = borderless ? View : Panel;

  return (
    <Root sx={sx}>
      <Post post={post}>
        <Header />
        <Content
          onPressCommentsCounter={handlePressCommentsCounter}
          sx={{ mt: post.type === POST_TYPE.document ? 0 : '$6' }}
        />
        <View sx={{ width: '100%', height: 1, bg: '$primary.main', opacity: 0.5, my: '$3' }} />
        <Toolbar onPressComment={handlePressCommentButton} sx={{ mb: '$2' }} />
        {Platform.OS === 'web' && (
          <AddComment
            model={{ type: COMMENTABLE_MODEL.post, id: post.id }}
            inputRef={commentInputRef}
            sx={{ mt: '$4', mb: '$4' }}
          />
        )}
        <View ref={commentsRef}>
          <Comments
            model={{ type: COMMENTABLE_MODEL.post, id: post.id }}
            sx={{ mt: '$4' }}
            onPressSeeMore={handlePressSeeMore}
            collapsed={!full}
          />
        </View>
      </Post>
    </Root>
  );
}

PostCard.propTypes = {
  id: CustomPropTypes.uuid.isRequired,
  sx: CustomPropTypes.style,
  visible: PropTypes.bool,
  full: PropTypes.bool,
  borderless: PropTypes.bool,
  onNotFoundError: PropTypes.func,
};

export default PostCard;
