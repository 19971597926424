import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import { View, Slider, Button, Flex } from '@ui/atoms';
import { Dialog } from '@ui/templates';
import useTranslate from '@hooks/useTranslate';

function AvatarCropper({ open = false, onClose, uri, onChange }) {
  const { t } = useTranslate();
  const editorRef = useRef();
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (open) {
      setZoom(1);
    }
  }, [open]);

  const handleSubmit = () => {
    editorRef.current.getImage().toBlob((blob) => onChange(blob));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} size="small">
      <View
        sx={{
          alignItems: 'center',
          m: '$-4',
          p: '$4',
          mb: 0,
          borderTopLeftRadius: '$2',
          borderTopRightRadius: '$2',
          overflow: 'hidden',
        }}
      >
        <AvatarEditor ref={editorRef} image={uri} width={350} height={350} scale={zoom} />
        <Slider sx={{ width: 200, height: 40, m: '$6' }} minimumValue={1} maximumValue={3} onValueChange={setZoom} />
        <Flex justifyContent="space-between" sx={{ width: '100%', px: '$4' }}>
          <Button title={t('common.verb.cancel')} variant="outlined" color="grey" onPress={onClose} />
          <Button title={t('common.verb.validate')} color="primary" onPress={handleSubmit} />
        </Flex>
      </View>
    </Dialog>
  );
}

AvatarCropper.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  uri: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

export default AvatarCropper;
