import { FlatList, Flex, Ionicons, Pressable, Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import { Panel, Neighbor } from '@ui/molecules';
import useNeighborsQuery from '@hooks/queries/useNeighborsQuery';
import { useNavigation } from '@react-navigation/native';
import NeighborSkeleton from '@ui/molecules/NeighborSkeleton';
import SCREENS from '@constants/Screens';
import RESIDENCE_USER_ROLE from '@constants/ResidenceUserRole';
import { useCallback } from 'react';

const emptyArray = [];

function ContactsWidget() {
  const { navigate } = useNavigation();

  // Récupère les voisins depuis la requête
  const {
    data: neighbors,
    isFetching,
    isFetched,
  } = useNeighborsQuery({
    placeholderData: emptyArray,
    select: (data) => data?.items ?? emptyArray,
  });

  // Filtrer les voisins selon leur rôle
  const residents = neighbors.filter((neighbor) => neighbor.role === RESIDENCE_USER_ROLE.resident);
  const jardiniers = neighbors.filter((neighbor) => neighbor.role === RESIDENCE_USER_ROLE.jardinier);
  const animators = neighbors.filter((neighbor) => neighbor.role === RESIDENCE_USER_ROLE.animator);
  const conseilSyndic = neighbors.filter((neighbor) => neighbor.role === RESIDENCE_USER_ROLE.conseilSyndic);
  const managers = neighbors.filter((neighbor) => neighbor.role === RESIDENCE_USER_ROLE.manager);

  // Fonction de rendu pour chaque voisin
  const renderNeighbor = useCallback(({ item }) => <Neighbor contact={item} sx={{ mt: '$3' }} />, []);

  // Fonction pour afficher le bouton "Show More"
  const renderShowMore = useCallback(
    () => (
      <Pressable onPress={() => navigate(SCREENS.neighbors)}>
        <Flex alignItems="center" sx={{ mt: '$3' }}>
          <Text>
            <FormattedMessage id="ui.ContactsWidget.showMore" />
          </Text>
          <Ionicons name="arrow-forward" sx={{ ml: '$1', color: '$text.primary' }} />
        </Flex>
      </Pressable>
    ),
    [navigate]
  );

  return (
    <Panel shadow>
      {/* Section pour les residents et jardiniers */}
      <View>
        <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
          <FormattedMessage id="ui.ContactsWidget.neighbors" />
        </Text>
        {isFetching ? (
          <View>
            <NeighborSkeleton sx={{ mt: '$3' }} />
            <NeighborSkeleton sx={{ mt: '$3' }} />
          </View>
        ) : (
          <FlatList
            data={0 < residents.length ? residents.slice(0, 2) : jardiniers.slice(0, 2)}
            sx={{ flexGrow: 0 }}
            renderItem={renderNeighbor}
            keyExtractor={(item) => item.id}
          />
        )}
        {isFetched && (0 < residents.length || 0 < jardiniers.length) && renderShowMore()}
      </View>

      {/* Section pour les animateurs et conseil syndical */}
      {(0 < animators.length || 0 < conseilSyndic.length) && (
        <View sx={{ mt: '$6' }}>
          <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
            <FormattedMessage id="ui.ContactsWidget.animators" />
          </Text>
          {isFetching ? (
            <View>
              <NeighborSkeleton sx={{ mt: '$3' }} />
              <NeighborSkeleton sx={{ mt: '$3' }} />
            </View>
          ) : (
            <FlatList
              data={0 < animators.length ? animators.slice(0, 2) : conseilSyndic.slice(0, 2)}
              sx={{ flexGrow: 0 }}
              renderItem={renderNeighbor}
              keyExtractor={(item) => item.id}
            />
          )}
          {isFetched && (0 < animators.length || 0 < conseilSyndic.length) && renderShowMore()}
        </View>
      )}

      {/* Section pour les managers (Kunagi) */}
      {0 < managers.length && (
        <View sx={{ mt: '$6' }}>
          <Text variant="subtitle2" sx={{ color: '$primary.main', mb: '$2' }}>
            <FormattedMessage id="common.name" />
          </Text>
          {isFetching ? (
            <NeighborSkeleton sx={{ mt: '$3' }} />
          ) : (
            <FlatList
              data={managers.slice(0, 2)}
              sx={{ flexGrow: 0 }}
              renderItem={renderNeighbor}
              keyExtractor={(item) => item.id}
            />
          )}
          {isFetched && 0 < managers.length && renderShowMore()}
        </View>
      )}
    </Panel>
  );
}

export default ContactsWidget;
