import { useState } from 'react';
import { Button, Ionicons, Text, View } from '@ui/atoms';
import { Rating } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function RatingForm({ onSubmit, loading = false }) {
  const { t } = useTranslate();
  const [value, setValue] = useState(0);

  const handleSubmit = () => {
    onSubmit({ rating: value });
  };

  return (
    <View>
      <Text sx={{ mb: '$2' }} variant="subtitle">
        <FormattedMessage id="ui.Survey.RatingForm.title" />
      </Text>
      <Rating size={40} value={value} onChange={setValue} />
      <View sx={{ mt: '$6' }}>
        <Button
          color="primary"
          title={t('ui.Survey.RatingForm.validate')}
          startIcon={<Ionicons name="checkmark" size={24} sx={{ color: '$common.white' }} />}
          disabled={value === 0}
          onPress={handleSubmit}
          loading={loading}
        />
      </View>
    </View>
  );
}

RatingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default RatingForm;
