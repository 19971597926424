
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        /* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import RESIDENCE_USER_ROLE from '@constants/ResidenceUserRole';
import userPlaceholder from '@src/assets/user-placeholder.jpg';
import Avatar, { SIZES } from '@ui/atoms/Avatar';
import View from '@ui/atoms/View';

  const ShieldCheckIcon = __ReactNativeSvgLoader(import('@src/assets/icons/shield-check.svg'));
  ;

  const SparklesIcon = __ReactNativeSvgLoader(import('@src/assets/icons/sparkles.svg'));
  ;
import useThemeColor from '@hooks/useThemeColor';
import Pressable from '@ui/atoms/Pressable';

const BADGE_SIZES = {
  small: 10,
  medium: 14,
  large: 18,
  extraLarge: 48,
};

const BADGE_OFFSET = {
  small: -3,
  medium: 0,
  large: 0,
  extraLarge: 0,
};

const BADGE_ICON_SIZE = {
  small: 6,
  medium: 10,
  large: 14,
  extraLarge: 32,
};

function UserAvatar({ user, size = 'medium', sx = undefined, hideBadge = false, onPress = undefined, ...props }) {
  const white = useThemeColor('$common.white');

  const showAdminBadge = !hideBadge && user.role === RESIDENCE_USER_ROLE.manager;
  const showAnimatorBadge = !hideBadge && !showAdminBadge && user.role === RESIDENCE_USER_ROLE.animator;

  const badgeSize = BADGE_SIZES[size];
  const badgeIconSize = BADGE_ICON_SIZE[size];
  const badgeOffset = BADGE_OFFSET[size];

  return (
    <Pressable
      disabled={typeof onPress !== 'function'}
      onPress={onPress}
      sx={{ height: SIZES[size], width: SIZES[size], position: 'relative', ...sx }}
      {...props}
    >
      <Avatar size={size} source={user.avatar?.small || userPlaceholder} />
      {showAdminBadge && (
        <View
          sx={{
            height: badgeSize,
            width: badgeSize,
            bg: '$primary.main',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: badgeSize / 2,
            position: 'absolute',
            top: badgeOffset,
            right: badgeOffset,
          }}
        >
          <ShieldCheckIcon height={badgeIconSize} width={badgeIconSize} color={white} />
        </View>
      )}
      {showAnimatorBadge && (
        <View
          sx={{
            height: badgeSize,
            width: badgeSize,
            bg: '$secondary.main',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: badgeSize / 2,
            position: 'absolute',
            top: badgeOffset,
            right: badgeOffset,
          }}
        >
          <SparklesIcon height={badgeIconSize} width={badgeIconSize} color={white} />
        </View>
      )}
    </Pressable>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.shape({
      small: PropTypes.string,
    }),
    role: PropTypes.oneOf(Object.values(RESIDENCE_USER_ROLE)),
  }).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
  sx: CustomPropTypes.style,
  hideBadge: PropTypes.bool,
  onPress: PropTypes.func,
};

export default UserAvatar;

        