import 'expo-dev-client';
import { useCallback, useState } from 'react';
import { useFonts } from '@expo-google-fonts/poppins';
import * as SplashScreen from 'expo-splash-screen';
import { Asset } from 'expo-asset';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import ROUTES from '@constants/Routes';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { DripsyProvider, ContainerQuery } from 'dripsy';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import ModalProvider from '@src/components/ModalProvider';
import theme from '@ui/theme';
import { IntlProvider } from 'react-intl';
import useAuthStore, { authStateSelector } from '@src/stores/authStore';
import Toast from '@src/components/Toast';
import AuthProvider from '@src/components/AuthProvider';
import AUTH_STATE from '@constants/AuthState';
import ReactQueryDevtools from '@src/components/ReactQueryDevtools';
import SocketProvider from '@src/components/SocketProvider';
import ReactQueryProvider from '@src/components/ReactQueryProvider';
import { Image, Text, View } from '@ui/atoms';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { Platform } from 'react-native';
import PushNotificationProvider from '@src/components/PushNotificationProvider';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import DialogProvider from '@src/components/DialogProvider';
import InAppNotificationProvider from '@src/components/InAppNotificationProvider';
import { Drawer } from '@ui/organisms';
import { MainStack, GuestStack } from '@src/navigation';
import NewIdentity from '@src/components/NewIdentity';
import FONTS from './constants/Fonts';
import locales from './translations';

// Déclarer les images avec require() en dehors du composant
const guestBackground = require('@src/assets/guest-background.jpg');
const logo = require('@src/assets/logo.png');

// Définir un composant BoldText pour éviter la définition inline dans le prop defaultRichTextElements
function BoldText({ children }) {
  return <Text variant="subtitle">{children}</Text>;
}

// Fonction pour mettre en cache les images
function cacheImages(images) {
  if (Platform.OS === 'web') {
    return Promise.resolve();
  }
  return Promise.all(
    images.map((image) => {
      if (typeof image === 'string') {
        return Image.prefetch(image);
      }
      return Asset.fromModule(image).downloadAsync();
    })
  );
}

export default function App() {
  const [fontsLoaded] = useFonts(FONTS);
  const authState = useAuthStore(authStateSelector);
  const [isReady, setReady] = useState(false);

  useAsyncEffect(async () => {
    await SplashScreen.preventAutoHideAsync();
    await cacheImages([guestBackground, logo]);
    setReady(true);
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (isReady && fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [isReady, fontsLoaded]);

  if (!isReady || !fontsLoaded) {
    return null;
  }

  return (
    <SafeAreaProvider>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style={authState === AUTH_STATE.loggedIn ? 'dark' : 'light'} />
      <GestureHandlerRootView style={{ flex: 1 }}>
        <NavigationContainer
          linking={ROUTES}
          theme={{
            ...DefaultTheme,
            colors: {
              ...DefaultTheme.colors,
              background: 'transparent',
            },
          }}
          documentTitle={{
            formatter: (options) => [options?.title, locales.fr['common.name']].filter((v) => v).join(' | '),
          }}
        >
          <DripsyProvider theme={theme}>
            <ContainerQuery sx={{ flex: 1, bg: '$grey.100' }}>
              <IntlProvider
                locale="fr"
                defaultLocale="fr"
                defaultRichTextElements={{
                  bold: BoldText,
                }}
                onWarn={() => null}
                messages={locales.fr}
              >
                <ReactQueryProvider>
                  <ActionSheetProvider>
                    <View onLayout={onLayoutRootView} sx={{ flex: 1 }}>
                      <NewIdentity>
                        <>
                          <AuthProvider>
                            {authState === AUTH_STATE.loggedIn ? (
                              <>
                                <SocketProvider />
                                <PushNotificationProvider />
                                <InAppNotificationProvider />
                                <MainStack />
                                <Drawer />
                                <ModalProvider />
                              </>
                            ) : (
                              <GuestStack />
                            )}
                          </AuthProvider>
                          <DialogProvider />
                          <Toast />
                          {Platform.OS === 'web' && <div id="datepicker-portal" />}
                        </>
                      </NewIdentity>
                    </View>
                  </ActionSheetProvider>
                  <ReactQueryDevtools />
                </ReactQueryProvider>
              </IntlProvider>
            </ContainerQuery>
          </DripsyProvider>
        </NavigationContainer>
      </GestureHandlerRootView>
    </SafeAreaProvider>
  );
}
