/* eslint-disable react/jsx-props-no-spreading */
import NativeSlider from '@react-native-community/slider';
import useThemeColor from '@hooks/useThemeColor';
import { useSx } from 'dripsy';
import CustomPropTypes from '@utils/CustomPropTypes';

function Slider({ sx = undefined, ...props }) {
  const sxify = useSx();
  const primary = useThemeColor('$primary.main');
  const grey = useThemeColor('$grey.500');

  return (
    <NativeSlider
      style={sxify(sx)}
      thumbTintColor={primary}
      minimumTrackTintColor={primary}
      maximumTrackTintColor={grey}
      {...props}
    />
  );
}

Slider.propTypes = {
  sx: CustomPropTypes.style,
};

export default Slider;
