import { ActivityIndicator, FlatList, Text, View } from '@ui/atoms';
import { NotificationRow } from '@ui/molecules';
import useThemeColor from '@hooks/useThemeColor';
import useNotificationsQuery from '@hooks/queries/useNotificationsQuery';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRefetchNotifications from '@hooks/useRefetchNotifications';
import useMarkNotificationsAsReadMutation from '@hooks/mutations/useMarkNotificationsAsReadMutation';
import useOpenNotification from '@hooks/useOpenNotification';
import { FormattedMessage } from 'react-intl';

function NotificationsList({ width, visible = false, onOpenNotification = undefined, ...props }) {
  const primaryColor = useThemeColor('$primary.main');
  const openNotificationLink = useOpenNotification();

  const [localPages, setLocalPages] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useNotificationsQuery();

  const { mutate: markAsRead } = useMarkNotificationsAsReadMutation();

  useEffect(() => {
    if (visible) {
      setLocalPages(null);
      markAsRead();
    }
  }, [markAsRead, visible]);

  useEffect(() => {
    if (localPages === null) {
      setLocalPages(data?.pages ?? null);
    }
  }, [data, localPages]);

  const refetch = useRefetchNotifications();

  const notifications = localPages?.flatMap((page) => page.items) ?? [];

  const handlePress = useCallback(
    (notification) => {
      if (!notification.id || !notification.data?.link) {
        return;
      }
      openNotificationLink(notification.id, notification.data?.link);
      if (typeof onOpenNotification === 'function') {
        onOpenNotification(notification);
      }
    },
    [onOpenNotification, openNotificationLink]
  );

  const renderItem = useCallback(
    ({ item }) => <NotificationRow onPress={handlePress} notification={item} sx={{ width }} />,
    [handlePress, width]
  );

  const handleRefresh = async () => {
    try {
      setRefreshing(true);
      await refetch();
      setLocalPages(null);
      markAsRead();
    } finally {
      setRefreshing(false);
    }
  };

  const handleEndReached = async () => {
    if (Array.isArray(localPages) && hasNextPage && !isFetchingNextPage) {
      const { data: nextData } = await fetchNextPage();
      const localPagesLength = localPages.length;
      const queryPagesLength = nextData.pages.length;
      const pagesToAdd = queryPagesLength - localPagesLength;

      if (pagesToAdd > 0) {
        setLocalPages((prev) => {
          const output = [...prev];
          for (let i = 0; i < pagesToAdd; i += 1) {
            const index = localPagesLength + i;
            output.push(nextData.pages[index]);
          }
          return output;
        });
      }
    }
  };

  return (
    <FlatList
      data={notifications}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      onEndReached={handleEndReached}
      onRefresh={handleRefresh}
      refreshing={refreshing}
      ListEmptyComponent={
        isLoading ? (
          <View sx={{ height: 300, width: 400, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size="small" color={primaryColor} />
          </View>
        ) : (
          <View sx={{ justifyContent: 'center', py: '$6' }}>
            <Text variant="subtitle" sx={{ textAlign: 'center' }}>
              <FormattedMessage id="ui.NotificationsList.empty" />
            </Text>
          </View>
        )
      }
      ListFooterComponent={
        isFetchingNextPage && (
          <View sx={{ py: '$3', justifyContent: 'center' }}>
            <ActivityIndicator size="small" color={primaryColor} />
          </View>
        )
      }
      {...props}
    />
  );
}

NotificationsList.propTypes = {
  width: PropTypes.number.isRequired,
  onOpenNotification: PropTypes.func,
  visible: PropTypes.bool,
};

export default NotificationsList;
