/* eslint-disable react/jsx-props-no-spreading */
import RNPickerSelect from 'react-native-picker-select';
import PropTypes from 'prop-types';
import { useSx } from 'dripsy';
import CustomPropTypes from '@utils/CustomPropTypes';
import Ionicons from '@ui/atoms/Ionicons';
import View from '@ui/atoms/View';

const SIZES = {
  medium: 48,
  small: 32,
};

function Select({ variant = 'contained', size = 'medium', error = false, sx = undefined, ...props }) {
  const sxFactory = useSx();

  const inputStyle = sxFactory({
    borderWidth: 0,
    bg: 'transparent',
    height: SIZES[size],
    paddingHorizontal: '$3',
    paddingRight: SIZES[size] + 4,
  });

  return (
    <View
      sx={{
        position: 'relative',
        height: SIZES[size],
        bg: '$common.white',
        borderRadius: '$1',
        borderWidth: 1,
        borderColor: '$grey.300',
        ...(error && {
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '$red.main',
        }),
        ...sx,
      }}
    >
      <Ionicons
        name="chevron-down"
        size={24}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          lineHeight: SIZES[size],
          textAlign: 'center',
          width: SIZES[size],
          height: SIZES[size],
        }}
      />
      <RNPickerSelect
        style={{
          placeholder: sxFactory({
            fontWeight: 'regular',
            fontSize: 14,
            color: '$grey.700',
          }),
          inputIOS: inputStyle,
          inputAndroid: inputStyle,
          inputWeb: {
            ...inputStyle,
            appearance: 'none',
          },
          ...sx,
        }}
        useNativeAndroidPickerStyle={false}
        {...props}
      />
    </View>
  );
}

Select.propTypes = {
  variant: PropTypes.oneOf(['contained']),
  size: PropTypes.oneOf(['medium', 'small']),
  error: PropTypes.bool,
  sx: CustomPropTypes.style,
};

export default Select;
