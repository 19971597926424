import React, { forwardRef, useRef } from 'react';
import { Ionicons, Image, Pressable, ActivityIndicator, View } from '@ui/atoms';
import useThemeColor from '@hooks/useThemeColor';
import PropTypes from 'prop-types';
import { AvatarCropper } from '@ui/organisms';
import { IMAGE_MIMES } from '@constants/MimeType';
import useDialog from '@hooks/useDialog';

const AvatarPicker = forwardRef(function AvatarPicker(
  { avatar = undefined, onChange, loading = false, size = 60, icon = null },
  ref
) {
  const grey = useThemeColor('$grey.500');
  const primaryColor = useThemeColor('$primary.main');
  const { open } = useDialog();

  const inputRef = useRef();

  const handlePress = async () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    const [file] = e.target.files;
    if (file) {
      e.target.value = '';
      open(AvatarCropper, { uri: URL.createObjectURL(file), onChange });
    }
  };

  const avatarRender = avatar ? (
    <Image
      source={typeof avatar === 'string' ? { uri: avatar } : avatar}
      sx={{ height: size, width: size, borderRadius: size / 2 }}
    />
  ) : (
    <Ionicons name="camera-outline" size={32} color={grey} />
  );

  if (ref) {
    // Assign the handlePress function to the ref for external usage.
    ref.current = { handlePress };
  }

  return (
    <>
      <input
        type="file"
        accept={IMAGE_MIMES.join(', ')}
        ref={inputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Pressable
        sx={{
          height: size,
          width: size,
          borderRadius: size / 2,
          bg: '$common.white',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onPress={handlePress}
      >
        {loading ? <ActivityIndicator color={primaryColor} size="small" /> : avatarRender}
        {icon && (
          <View
            sx={{
              position: 'absolute',
              right: -6,
              bottom: 6,
              bg: '$primary.main',
              width: 25,
              height: 25,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 100,
              borderWidth: 3,
              borderColor: '$common.white',
            }}
          >
            {icon}
          </View>
        )}
      </Pressable>
    </>
  );
});

AvatarPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.number,
  icon: PropTypes.node,
};

export default React.memo(AvatarPicker);
