/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import Pressable from '@ui/atoms/Pressable';
import Ionicons from '@ui/atoms/Ionicons';
import View from '@ui/atoms/View';
import Text from '@ui/atoms/Text';
import CustomPropTypes from '@utils/CustomPropTypes';
import { Platform } from 'react-native-web';

const COLORS = {
  primary: {
    main: '$primary.main',
    contrast: '$common.white',
  },
};

function Checkbox({
  label,
  labelSx = undefined,
  color = 'primary',
  checked = false,
  onChange = undefined,
  disabled = false,
  sx = undefined,
  checkboxSx = undefined,
  ...props
}) {
  const handleChange = () => {
    if (typeof onChange === 'function') {
      onChange(!checked);
    }
  };

  return (
    <Pressable
      onPress={disabled ? undefined : handleChange}
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        ...sx,
      }}
      {...props}
    >
      <View
        sx={{
          height: 16,
          width: 16,
          borderRadius: 2,
          borderWidth: 2,
          borderColor: COLORS[color].main,
          alignItems: 'center',
          justifyContent: 'center',
          mr: '$2',
          bg: checked ? COLORS[color].main : undefined,
          ...checkboxSx,
        }}
      >
        {checked && (
          <Ionicons
            name="checkmark-sharp"
            size={14}
            sx={{
              color: COLORS[color].contrast,
              position: 'relative',
              top: Platform.select({ web: 0, other: -1 }),
              right: Platform.OS === 'android' ? 3 : 0,
            }}
          />
        )}
      </View>
      <View>{typeof label === 'string' ? <Text sx={labelSx}>{label}</Text> : label}</View>
    </Pressable>
  );
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  color: PropTypes.oneOf(['primary']),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  sx: CustomPropTypes.style,
  labelSx: CustomPropTypes.style,
  checkboxSx: CustomPropTypes.style,
};

export default Checkbox;
