import { Platform, TouchableWithoutFeedback } from 'react-native';
import PropTypes from 'prop-types';
import { View } from '@ui/atoms';
import useDeviceDimensions from '@hooks/useDeviceDimensions';

const SIZES = {
  small: 400,
  medium: 600,
  large: 900,
};

function Dialog({ onClose, children, size = 'medium' }) {
  const dimensions = useDeviceDimensions();

  return (
    <View
      sx={{
        position: Platform.select({ web: 'fixed', default: 'absolute' }),
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        overflow: 'scroll',
        backgroundColor: [null, 'rgba(0,0,0,.3)'],
        flexGrow: Platform.select({ web: 'unset', default: undefined }),
      }}
    >
      <View
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          minHeight: dimensions.height,
          width: '100%',
          paddingVertical: [0, 50],
        }}
      >
        <TouchableWithoutFeedback onPress={() => onClose()}>
          <View
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
            }}
          />
        </TouchableWithoutFeedback>
        <View
          sx={{
            width: ['100%', SIZES[size]],
            maxWidth: ['100%', '90%'],
            bg: '$common.white',
            boxShadow: 'md',
            borderRadius: [0, '$2'],
            minHeight: [dimensions.height, 'auto'],
            overflow: 'hidden',
          }}
        >
          {children}
        </View>
      </View>
    </View>
  );
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.keys(SIZES)),
};

export default Dialog;
