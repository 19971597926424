import useThemeColor from '@hooks/useThemeColor';
import { Pressable, View } from '@ui/atoms';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function Rating({ value, onChange = undefined, size }) {
  const primaryColor = useThemeColor('$primary.main');
  const roundedNumber = Math.round(value * 2) / 2;

  const fillCount = Math.floor(roundedNumber);
  const halfCount = roundedNumber - Math.floor(roundedNumber) > 0;
  const emptyCount = 5 - Math.ceil(roundedNumber);

  const readonly = typeof onChange !== 'function';
  const StarRoot = readonly ? View : Pressable;

  if (!readonly && !Number.isInteger(value)) {
    throw new Error('Rating in edit mode requires integer value.');
  }

  return (
    <View sx={{ flexDirection: 'row' }}>
      {[...Array(fillCount)].map((e, i) => (
        <StarRoot sx={{ mx: '$1' }} key={i} onPress={() => onChange(i + 1)}>
          <Svg key={i} viewBox="0 0 576 512" height={size} width={size}>
            <Path
              fill={primaryColor}
              d="M316.7 17.8l65.43 132.4l146.4 21.29c26.27 3.796 36.79 36.09 17.75 54.59l-105.9 102.1l25.05 145.5c4.508 26.31-23.23 45.9-46.49 33.7L288 439.6l-130.9 68.7C133.8 520.5 106.1 500.9 110.6 474.6l25.05-145.5L29.72 226.1c-19.03-18.5-8.516-50.79 17.75-54.59l146.4-21.29l65.43-132.4C271.1-6.083 305-5.786 316.7 17.8z"
            />
          </Svg>
        </StarRoot>
      ))}
      {readonly && halfCount && (
        <Svg viewBox="0 0 576 512" height={size} width={size}>
          <Path
            fill={primaryColor}
            d="M528.5 171.5l-146.4-21.29l-65.43-132.4c-5.873-11.83-17.31-17.81-28.78-17.81c-11.39 0-22.82 5.89-28.7 17.8l-65.43 132.4L47.47 171.5C21.2 175.3 10.68 207.6 29.72 226.1l105.9 102.1l-25.04 145.5C107 495.3 123.6 512 142.2 512c4.932 0 10.01-1.172 14.88-3.75L288 439.6l130.9 68.7c4.865 2.553 9.926 3.713 14.85 3.713c18.61 0 35.21-16.61 31.65-37.41l-25.05-145.5l105.9-102.1C565.3 207.6 554.8 175.3 528.5 171.5zM406.9 294.7L388.8 312.3l23.83 138.4L288 385.4V68l62.31 126.1l139.2 20.25L406.9 294.7z"
          />
        </Svg>
      )}
      {[...Array(emptyCount)].map((e, i) => (
        <StarRoot sx={{ mx: '$1' }} key={i} onPress={() => onChange(i + fillCount + 1)}>
          <Svg key={i} viewBox="0 0 576 512" height={size} width={size}>
            <Path
              fill={primaryColor}
              d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
            />
          </Svg>
        </StarRoot>
      ))}
    </View>
  );
}

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

export default Rating;
