import { Flex, Skeleton, View } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';

function EventCardSkeleton({ sx = undefined }) {
  return (
    <View
      sx={{
        borderWidth: 1,
        borderColor: '$grey.300',
        borderRadius: '$1',
        height: 'auto',
        overflow: 'hidden',
        backgroundColor: '$common.white',
        ...sx,
      }}
    >
      <Skeleton width="100%" height={250} radius={0} />
      <View sx={{ p: '$4' }}>
        <View sx={{ width: '100%' }}>
          <Skeleton width={200} height={18} />
          <Skeleton width="100%" height={40} sx={{ mt: '$2', mb: '$4' }} />
        </View>
        <Flex alignItems="center">
          <Skeleton height={16} width={16} radius={8} sx={{ mr: '$2' }} />
          <Skeleton width={140} height={8} />
        </Flex>
      </View>
    </View>
  );
}

EventCardSkeleton.propTypes = {
  sx: CustomPropTypes.style,
};

export default EventCardSkeleton;
