import { Ionicons, Pressable, Text, View } from '@ui/atoms';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import Dropdown from '@ui/templates/Dropdown';
import HEADER_HEIGHT from '@constants/HeaderHeight';
import { DropdownOptionType } from '@ui/templates/Dropdown/DropdownPropTypes';

function ScreenHeader({ title, backIcon, actions = [], insetTop = 0 }) {
  const { goBack } = useNavigation();

  const backAction = (
    <Pressable
      sx={{
        width: HEADER_HEIGHT,
        height: HEADER_HEIGHT,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onPress={goBack}
    >
      <Ionicons name={backIcon} size={24} />
    </Pressable>
  );

  const dropdownAction =
    actions.length > 0 ? (
      <Dropdown options={actions} width={200} offset={10}>
        <View
          sx={{
            width: HEADER_HEIGHT,
            height: HEADER_HEIGHT,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Ionicons name="ellipsis-vertical-sharp" size={24} />
        </View>
      </Dropdown>
    ) : (
      <View sx={{ width: HEADER_HEIGHT, height: HEADER_HEIGHT }} />
    );

  const leftAction = Platform.select({ web: dropdownAction, default: backAction });
  const rightAction = Platform.select({ web: backAction, default: dropdownAction });

  return (
    <View sx={{ zIndex: 10 }}>
      <View
        sx={{
          pt: insetTop,
          flexDirection: 'row',
          justifyContent: 'space-between',
          px: '$2',
          height: insetTop + HEADER_HEIGHT,
          ...Platform.select({
            web: {
              position: ['fixed', 'static'],
              top: 0,
            },
          }),
          backgroundColor: '$grey.100',
          width: '100%',
          boxShadow: 'sm',
          zIndex: 300,
          alignItems: 'center',
        }}
      >
        {leftAction}
        <Text variant="subtitle2" numberOfLines={1} sx={{ flex: 1, textAlign: 'center' }}>
          {title}
        </Text>
        {rightAction}
      </View>
    </View>
  );
}

ScreenHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backIcon: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(DropdownOptionType),
  insetTop: PropTypes.number,
};

export default ScreenHeader;
