/* eslint-disable react/jsx-props-no-spreading */
import { Ionicons, Pressable, View } from '@ui/atoms';
import { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import WebSwiper from 'react-native-web-swiper';

const Slider = forwardRef(function Swiper(
  { children, height, defaultIndex = 0, onIndexChanged = undefined, ...props },
  forwardedRef
) {
  const fallbackRef = useRef();
  const ref = forwardedRef ?? fallbackRef;
  const initRef = useRef(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setLastIndex(ref.current.count - 1);
  });

  useLayoutEffect(() => {
    if (defaultIndex && false === initRef.current) {
      ref.current.goTo(defaultIndex);
      initRef.current = true;
    }
  }, [defaultIndex, ref]);

  const handleSwiperNext = () => ref.current.goToNext();

  const handleSwiperPrev = () => ref.current.goToPrev();

  const handleIndexChange = (index) => {
    setActiveIndex(index);
    if ('function' === typeof onIndexChanged) {
      onIndexChanged(index);
    }
  };

  const showNext = activeIndex < lastIndex;
  const showPrev = 0 < activeIndex;

  return (
    <View sx={{ height, position: 'relative' }}>
      {showPrev && (
        <Pressable
          onPress={handleSwiperPrev}
          sx={{
            backgroundColor: '$grey.100',
            borderRadius: 20,
            height: 40,
            width: 40,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: height / 2 - 20,
            left: 20,
            zIndex: 200,
            boxShadow: 'sm',
          }}
        >
          <Ionicons name="arrow-back" size={24} sx={{ color: '$primary.main' }} />
        </Pressable>
      )}
      {showNext && (
        <Pressable
          onPress={handleSwiperNext}
          sx={{
            backgroundColor: '$grey.100',
            borderRadius: 20,
            height: 40,
            width: 40,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: height / 2 - 20,
            right: 20,
            zIndex: 200,
          }}
        >
          <Ionicons name="arrow-forward" size={24} sx={{ color: '$primary.main' }} />
        </Pressable>
      )}
      <WebSwiper
        innerContainerStyle={{ height }}
        onIndexChanged={handleIndexChange}
        controlsEnabled={false}
        ref={ref}
        {...props}
      >
        {children}
      </WebSwiper>
    </View>
  );
});

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number.isRequired,
  onIndexChanged: PropTypes.func,
  defaultIndex: PropTypes.number,
};

export default Slider;
