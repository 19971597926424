import { Flex, View, Skeleton } from '@ui/atoms';
import { Panel } from '@ui/molecules';
import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';

function SurveySkeleton({ sx = undefined, borderless = false }) {
  const Root = borderless ? View : Panel;

  return (
    <Root sx={sx}>
      <View sx={{ mb: '$5' }}>
        <Flex alignItems="center">
          <Skeleton height={50} width={50} radius={25} />
          <View sx={{ ml: '$2' }}>
            <Skeleton width={140} height={14} sx={{ mb: '$1' }} />
            <Skeleton width={90} height={14} />
          </View>
        </Flex>
      </View>
      <View sx={{ width: '100%' }}>
        <Skeleton width={200} height={18} />
        <Skeleton width="100%" height={70} sx={{ mt: '$2', mb: '$5' }} />
      </View>
    </Root>
  );
}

SurveySkeleton.propTypes = {
  sx: CustomPropTypes.style,
  borderless: PropTypes.bool,
};

export default SurveySkeleton;
