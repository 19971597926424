import { useWindowDimensions } from 'react-native';
import { Pressable, View } from '@ui/atoms';
import { Fragment, useEffect, useRef, useState } from 'react';
import PopoverPropTypes from '@ui/templates/Popover/PopoverPropTypes';
import {
  shift,
  useFloating,
  offset as floatingOffset,
  FloatingPortal,
  FloatingOverlay,
  autoUpdate,
} from '@floating-ui/react-dom-interactions';

function Popover({
  trigger,
  children,
  width = undefined,
  placement = 'bottom-start',
  offset = 0,
  open = undefined,
  setOpen = undefined,
  sx = undefined,
  unmount = true,
}) {
  const dimensions = useWindowDimensions();
  const [localOpen, localSetOpen] = useState(false);

  // Utilise les props passées ou, si elles ne sont pas définies, l'état local.
  const effectiveOpen = open !== undefined ? open : localOpen;
  const effectiveSetOpen = setOpen !== undefined ? setOpen : localSetOpen;

  const ref = useRef();
  const triggerRef = useRef();

  const { x, y, reference, floating, update } = useFloating({
    placement,
    strategy: 'absolute',
    middleware: [shift(), floatingOffset(offset)],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    const listener = (event) => {
      if (triggerRef.current?.contains(event.target)) {
        return;
      }

      effectiveSetOpen((prev) => {
        const isClickInside = ref.current?.contains(event.target);
        if (!isClickInside) {
          return false;
        }
        return prev;
      });
    };

    document.addEventListener('click', listener, { capture: true });
    return () => document.removeEventListener('click', listener);
  }, [effectiveSetOpen]);

  useEffect(() => {
    update();
  }, [update, dimensions.width, effectiveOpen]);

  const Overlay = effectiveOpen ? FloatingOverlay : Fragment;
  const overlayProps = effectiveOpen ? { lockScroll: true } : {};

  return (
    <>
      <Pressable
        ref={(el) => {
          reference(el);
          triggerRef.current = el;
        }}
        onPress={() => effectiveSetOpen((prev) => !prev)}
      >
        {trigger}
      </Pressable>
      <FloatingPortal>
        {(effectiveOpen || !unmount) && (
          <Overlay {...overlayProps}>
            <View
              ref={floating}
              sx={{
                position: 'absolute',
                top: y ?? 0,
                left: x ?? 0,
                visibility: effectiveOpen ? 'visible' : 'hidden',
                width,
              }}
            >
              <View ref={ref} sx={sx}>
                {children}
              </View>
            </View>
          </Overlay>
        )}
      </FloatingPortal>
    </>
  );
}

Popover.propTypes = PopoverPropTypes;

export default Popover;
