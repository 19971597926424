import { Pressable, Text, View } from '@ui/atoms';
import { useSx } from 'dripsy';
import DropdownPropTypes from '@ui/templates/Dropdown/DropdownPropTypes';
import Popover from '@ui/templates/Popover';
import { useState } from 'react';

function Dropdown({
  options,
  children,
  width = undefined,
  placement = 'bottom-start',
  offset = 0,
  disabled = false,
  rtl = false,
}) {
  const sxify = useSx();
  const [open, setOpen] = useState(false);

  const handlePress = (option) => {
    option.onPress();
    setOpen(false);
  };

  const visibleOptions = options.filter((option) => option.visible !== false);

  if (visibleOptions.length === 0) {
    return null;
  }

  return (
    <Popover
      open={disabled ? false : open}
      setOpen={disabled ? () => null : setOpen}
      trigger={children}
      placement={placement}
      width={width}
      offset={offset}
      sx={{
        bg: '$common.white',
        borderWidth: 1,
        borderColor: '$grey.300',
        boxShadow: 'md',
        py: '$2',
        borderRadius: '$1',
      }}
    >
      {visibleOptions.map((option) => (
        <Pressable
          key={option.label}
          onPress={() => handlePress(option)}
          style={(state) => (state.hovered ? sxify({ bg: '$grey.100' }) : {})}
        >
          <View
            sx={{
              display: 'flex',
              flexDirection: rtl ? 'row-reverse' : 'row',
              alignItems: 'center',
              py: '$3',
              px: '$4',
            }}
          >
            {option.icon ?? null}
            <Text
              numberOfLines={1}
              sx={{
                ml: '$3',
                color: option.destructive ? '$red.main' : undefined,
                textAlign: rtl ? 'right' : 'left',
              }}
            >
              {option.label}
            </Text>
          </View>
        </Pressable>
      ))}
    </Popover>
  );
}

Dropdown.propTypes = DropdownPropTypes;

export default Dropdown;
